import { TokenContentApiReponse } from '@/pages/api/tokens/content/_types';
import useSWR, { SWRResponse } from 'swr';

import { ClientNetworkError } from '@/lib/errors/client';

import { routes } from '@/utils/routes';
import { objectToQueryString } from '@/utils/string';

export const NATIVE_USDC_HELP_TEXT =
  'Native USDC is issued by Circle and is redeemable 1:1 for US dollars.';

const fetcher = async ([tokenAddress, chainId]: [string | undefined, number | undefined]) => {
  if (!tokenAddress || !chainId) return undefined;

  const response = await fetch(
    `${routes.api.TOKENS_CONTENT}${objectToQueryString({ tokenAddress, chainId })}`,
  );
  if (!response.ok) throw new ClientNetworkError(response.statusText);
  const tokenContentApiResponseData = (await response.json()) as TokenContentApiReponse;

  return tokenContentApiResponseData;
};

/**
 * Fetches Custom Content for USDC token pages and others.
 */
export const useCustomContent = (
  tokenAddress: string | undefined,
  chainId: number | undefined,
): SWRResponse<TokenContentApiReponse | undefined> => {
  return useSWR([tokenAddress, chainId], fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
};
