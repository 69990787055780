import * as RadixTooltip from '@radix-ui/react-tooltip';
import { ComponentProps, forwardRef } from 'react';

import { popoverContentClass } from '../../styles/popover.css';
import { classNames } from '../../utils/classnames';
import { contentClass } from './index.css';

function Provider({
  children,
  delayDuration = 50,
  disableHoverableContent,
  skipDelayDuration,
}: ComponentProps<typeof RadixTooltip.Provider>) {
  return (
    <RadixTooltip.Provider
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
      skipDelayDuration={skipDelayDuration}
    >
      {children}
    </RadixTooltip.Provider>
  );
}

const Root = RadixTooltip.Root;
const Portal = RadixTooltip.Portal;
const Trigger = RadixTooltip.Trigger;
const Content = forwardRef<HTMLDivElement, ComponentProps<typeof RadixTooltip.Content>>(
  function Content({ children, className, ...rest }, forwardedRef) {
    return (
      <RadixTooltip.Content
        ref={forwardedRef}
        className={classNames(contentClass, popoverContentClass, className)}
        sideOffset={12}
        {...rest}
      >
        {children}
      </RadixTooltip.Content>
    );
  },
);

const Tooltip = { Provider, Root, Portal, Trigger, Content };

export default Tooltip;
