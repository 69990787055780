import type { ConnectedWallet, ConnectedSolanaWallet } from '@privy-io/react-auth';

import { CHAIN_IDS, solana } from '@/constants/chain';

import { useSolWalletDisconnected } from '@/hooks/useSolWalletDisconnected';

import { ImageChainOverlayIcon } from '@/ui/Icons/TokenChainIcon';

type Web3WalletIconProps = {
  wallet: ConnectedWallet | ConnectedSolanaWallet;
  showChain?: boolean;
  size?: number;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export function Web3WalletIcon({
  wallet,
  size = 18,
  showChain = true,
  ...props
}: Web3WalletIconProps) {
  const { isDisconnected } = useSolWalletDisconnected(wallet);

  if (isDisconnected && wallet.type === solana.name.toLowerCase()) {
    return null;
  }

  return wallet.meta.icon ? (
    // TODO: If we use our own wallet icons in the future, we can remove this
    // and use next/image for optimization. For now, we use a native <img> tag
    // to render the Base64 icon directly from Privy.
    // See: https://linear.app/0xproject/issue/MAT-4454
    // eslint-disable-next-line @next/next/no-img-element
    <ImageChainOverlayIcon
      logoUrl={wallet.meta.icon}
      size={size}
      className={props.className}
      chainId={wallet.type === 'solana' ? CHAIN_IDS.SOLANA : CHAIN_IDS.MAINNET}
    />
  ) : // <div>
  // <img
  //   width={18}
  //   height={18}
  //   src={wallet.meta.icon}
  //   alt={`${wallet.meta.name} logo`}
  //   {...props}
  // />
  // </div>
  null;
}
