// utils
import { useConnectWallet } from '@privy-io/react-auth';
import { useCallback } from 'react';
import { useChainId } from 'wagmi';

import { SUPPORTED_CHAINS_BY_CHAIN_ID } from '@/constants/chain';

import { SOLANA_PHASE1_COOKIE_KEY, useEnabledFeatures, useFeature } from '@/hooks/useFeature';
import { useMatchaWallets } from '@/hooks/useMatchaWallets';

import { nativeTokenBalanceSelectors, useBalanceStore } from '@/store/balance';

import {
  MatchaTooltip,
  MatchaTooltipContent,
  MatchaTooltipPopover,
  MatchaTooltipTrigger,
} from '@/ui/MatchaTooltip';

import { getChainIdNumberFromCaip2ChainId } from '@/utils/privy';
import { getDisconnectedKey } from '@/utils/solana';

import { EVENT_NAME, logEvent } from '../../../utils/amplitude';
import { EthereumAddress } from '../../../utils/models';
import AccountButtonDropdown from '../AccountButtonDropdown';
import AccountButtonDropdownSolana from '../AccountButtonDropdownSolana';
import {
  connectButtonClass,
  createWalletButtonClass,
  featureFlagIndicatorButtonClass,
} from './index.css';

interface AppConnectButtonProps {
  /** The button's children. */
  children: React.ReactNode;
  /** The callback to run when the button is clicked. */
  onClick: () => void;
}

/*
 * A button to display when the user is disconnected or connected to an
 * unsupported network.
 *
 * @param {AppConnectButtonProps} props - The component's props.
 */
const AppConnectButton = ({ children, onClick }: AppConnectButtonProps): JSX.Element => (
  <button className={connectButtonClass} onClick={onClick}>
    {children}
  </button>
);

/**
 * Component that renders the connect button in the header, or the account
 * details & network select dropdowns if the user is connected.
 */
export const HeaderConnectButton = (): JSX.Element => {
  const { connectWallet } = useConnectWallet({
    onSuccess: ({ wallet }) => {
      localStorage.removeItem(getDisconnectedKey(wallet));
    },
    onError: () => {
      logEvent({ name: EVENT_NAME.CONNECT_WALLET_ERROR });
    },
  });
  const { nativeTokenBalances } = useBalanceStore(nativeTokenBalanceSelectors);
  const { solanaWallet, ethereumWallet } = useMatchaWallets();
  const wagmiChainId = useChainId();
  const isSolanaPhase1 = useFeature(SOLANA_PHASE1_COOKIE_KEY);

  const hasConnectedWallet = ethereumWallet || solanaWallet;
  const chainId =
    hasConnectedWallet && ethereumWallet
      ? getChainIdNumberFromCaip2ChainId(ethereumWallet.chainId)
      : wagmiChainId;

  // unsupported network
  if (chainId && !SUPPORTED_CHAINS_BY_CHAIN_ID[chainId]) {
    return <AppConnectButton onClick={connectWallet}>Unsupported Network</AppConnectButton>;
  }

  const handleConnectClick = (): void => {
    connectWallet();
    logEvent({ name: EVENT_NAME.CLICK_CONNECT_WALLET });
  };

  // not connected
  const shouldShowConnectButton = isSolanaPhase1
    ? !solanaWallet && !ethereumWallet
    : !ethereumWallet;

  if (shouldShowConnectButton) {
    return <AppConnectButton onClick={handleConnectClick}>Connect</AppConnectButton>;
  }

  // connected account
  return isSolanaPhase1 ? (
    <AccountButtonDropdownSolana />
  ) : (
    <AccountButtonDropdown
      /* At this point we are sure that ethereumWallet is not undefined
       * because we have already checked for it with the shouldShowConnectButton
       * check above. (the !isSolanaPhase1 path explicitly checks for ethereumWallet)
       */
      address={ethereumWallet!.address as EthereumAddress}
      balanceFormatted={nativeTokenBalances.evm}
      balanceSymbol={SUPPORTED_CHAINS_BY_CHAIN_ID[chainId].nativeCurrency.symbol}
    />
  );
};

/**
 * Component that renders the create wallet button in the header
 */
export const HeaderCreateWalletButton = (): JSX.Element => {
  const { connectWallet } = useConnectWallet({
    onSuccess: ({ wallet }) => {
      localStorage.removeItem(getDisconnectedKey(wallet));
    },
    onError: () => {
      logEvent({ name: EVENT_NAME.CONNECT_WALLET_ERROR });
    },
  });

  const onCreateWalletClick = useCallback(() => {
    connectWallet({
      walletList: ['coinbase_wallet'],
    });
  }, [connectWallet]);

  return (
    <button className={createWalletButtonClass} onClick={onCreateWalletClick}>
      Create wallet
    </button>
  );
};

/**
 * Component that renders the feature flag indicator in the header.
 */
export const FeatureFlagIndicator = () => {
  const featureStatus = useEnabledFeatures();
  if (!featureStatus.length) return null;

  const featuresString = featureStatus.map((f) => `"${f}"`).join(', ');

  return (
    <MatchaTooltip>
      <MatchaTooltipTrigger>
        <div className={featureFlagIndicatorButtonClass} hidden={!featureStatus.length}>
          ⛳️
        </div>
      </MatchaTooltipTrigger>
      <MatchaTooltipContent>
        <span>
          You have the {featuresString} feature flag{featureStatus.length > 1 && 's'} enabled.
        </span>
      </MatchaTooltipContent>
      <MatchaTooltipPopover sideOffset={8} />
    </MatchaTooltip>
  );
};

export const ConnectEthereumWalletButton = (): JSX.Element => {
  const { connectWallet } = useConnectWallet({
    onSuccess: ({ wallet }) => {
      localStorage.removeItem(getDisconnectedKey(wallet));
    },
  });

  const handleConnectClick = (): void => {
    connectWallet();
    logEvent({ name: EVENT_NAME.CLICK_CONNECT_WALLET });
  };

  return <AppConnectButton onClick={handleConnectClick}>Connect Ethereum Wallet</AppConnectButton>;
};
