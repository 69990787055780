import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { Address } from 'viem';
import { useEnsName } from 'wagmi';

import { tokenFormatter } from '@/lib/formatting';

import { WRAPPED_NATIVE_ADDRESSES } from '@/constants/addresses';

import useIsSmartWallet from '@/hooks/useIsSmartWallet';

import { accountSettingsSelectors, useAccountStore } from '@/store/account';
import { updateCheckoutStepSelector, useTradeStore } from '@/store/trade';

import {
  popoverAccessoryIconClass,
  popoverIconClass,
  popoverItemClass,
  walletProfileContainerClass,
  walletProfileLeftContainerClass,
} from '@/styles/popover.css';

import { ArrowUpRightIcon, CopyIcon, DisconnectIcon, EditIcon, ProfileIcon } from '@/ui/Icons';
import { Avatar } from '@/ui/Icons/Avatar';
import Toast from '@/ui/Toast';

import { formatShortAddress } from '@/utils/address';
import { EVENT_NAME, logEvent } from '@/utils/amplitude';
import { routes } from '@/utils/routes';
import { truncate } from '@/utils/string';

import { useMatchaWallets } from '../../../hooks/useMatchaWallets';
import { getConnectedChain } from '../../../utils/wallet';
import BalanceUsd from './BalanceUsd';
import {
  accountAvatarDropdownButtonClass,
  accountAvatarIconButtonClass,
  accountBalanceClass,
  accountButtonClass,
  accountHeaderClass,
  accountNameClass,
  accountPopoverClass,
  balanceUSDClass,
  buttonDisplayNameClass,
  itemsListClass,
} from './index.css';

const DeferredRenameWalletModal = dynamic(() => import('../../Modal/RenameWalletModal'));

type AccountButtonDropdownProps = {
  /** Ethereum address of the account. */
  address: Address;
  /** The account balance formatted as a string. */
  balanceFormatted?: string;
  /** The account balance symbol. */
  balanceSymbol?: string;
};

/**
 * A dropdown menu with account details.
 */
const AccountButtonDropdown = ({
  address,
  balanceFormatted,
  balanceSymbol,
}: AccountButtonDropdownProps) => {
  // state
  const [open, setOpen] = useState<boolean>(false);
  const [isRenameWalletOpen, setIsRenameWalletOpen] = useState<boolean>(false);
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const { settings } = useAccountStore(accountSettingsSelectors);
  const updateCheckoutStep = useTradeStore(updateCheckoutStepSelector);
  const { data: ensName } = useEnsName({ address });

  // hooks
  const { ethereumWallet } = useMatchaWallets();
  const chain = getConnectedChain(ethereumWallet);
  const isSmartWallet = useIsSmartWallet({ chainId: chain?.id });

  // event handlers
  const handleOpenWalletProfilePage = () => {
    logEvent({ name: EVENT_NAME.CLICK_CONNECT_WALLET_PROFILE });
    window.open(routes.COINBASE_SMART_WALLET_URL, '_blank');
  };

  const handleOpenRenameWalletModal = () => {
    logEvent({ name: EVENT_NAME.CLICK_CONNECT_RENAME_WALLET });
    setOpen(false);
    setIsRenameWalletOpen(true);
  };

  const handleCopyAddress = async () => {
    logEvent({ name: EVENT_NAME.CLICK_CONNECT_COPY_ADDRESS });
    await navigator.clipboard.writeText(address);
    setToastOpen(true);
  };

  const handleDisconnect = () => {
    logEvent({ name: EVENT_NAME.CLICK_CONNECT_DISCONNECT_WALLET });
    ethereumWallet?.disconnect();
    updateCheckoutStep('select');
  };

  // determine account name
  let buttonDisplayName: string, dropdownDisplayName: string;
  const storedName = settings[address]?.name;

  if (storedName) {
    buttonDisplayName = truncate(storedName, 15, true);
    dropdownDisplayName = truncate(storedName, 20, true);
  } else if (ensName) {
    buttonDisplayName = ensName;
    dropdownDisplayName = ensName;
  } else {
    buttonDisplayName = formatShortAddress(address);
    dropdownDisplayName = formatShortAddress(address, 12);
  }

  return (
    <>
      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <DropdownMenu.Trigger className={accountButtonClass}>
          <Avatar address={address} className={accountAvatarIconButtonClass} />
          <span className={buttonDisplayNameClass} data-privacy-mask="true">
            {buttonDisplayName}
          </span>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content align="end" className={accountPopoverClass}>
            <Avatar address={address} className={accountAvatarDropdownButtonClass} />

            <div className={accountHeaderClass}>
              <h5 className={accountNameClass} data-privacy-mask="true">
                {dropdownDisplayName}
              </h5>

              {balanceFormatted && (
                <p className={accountBalanceClass}>
                  {tokenFormatter.format(balanceFormatted, { symbol: balanceSymbol })}
                  &nbsp;·&nbsp;
                  {chain?.id && (
                    <BalanceUsd
                      address={WRAPPED_NATIVE_ADDRESSES[chain.id]}
                      balance={balanceFormatted}
                      chainId={chain.id}
                      className={balanceUSDClass}
                    />
                  )}
                </p>
              )}
            </div>

            <ul className={itemsListClass}>
              {isSmartWallet && (
                <DropdownMenu.Item
                  className={popoverItemClass}
                  onSelect={handleOpenWalletProfilePage}
                >
                  <div className={walletProfileContainerClass}>
                    <div className={walletProfileLeftContainerClass}>
                      <ProfileIcon className={popoverIconClass} />
                      Wallet Profile
                    </div>
                    <ArrowUpRightIcon className={popoverAccessoryIconClass} />
                  </div>
                </DropdownMenu.Item>
              )}

              <DropdownMenu.Item
                className={popoverItemClass}
                onSelect={handleOpenRenameWalletModal}
              >
                <EditIcon className={popoverIconClass} />
                Rename Wallet
              </DropdownMenu.Item>

              <DropdownMenu.Item className={popoverItemClass} onSelect={handleCopyAddress}>
                <CopyIcon className={popoverIconClass} />
                Copy Address
              </DropdownMenu.Item>

              <DropdownMenu.Item className={popoverItemClass} onSelect={handleDisconnect}>
                <DisconnectIcon className={popoverIconClass} />
                Disconnect Wallet
              </DropdownMenu.Item>
            </ul>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      <DeferredRenameWalletModal
        isOpen={isRenameWalletOpen}
        onClose={() => setIsRenameWalletOpen(false)}
        onOpenChange={setIsRenameWalletOpen}
      />

      <Toast
        description="Copied to clipboard."
        openState={toastOpen}
        onOpenChange={setToastOpen}
        type="success"
      />
    </>
  );
};

export default AccountButtonDropdown;
