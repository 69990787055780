import Decimal from 'decimal.js-light';

import * as errors from './errors';
import Formatter from './formatter';
import type { Amount, Boundaries } from './types';

/**
 * Displays human readable short amounts of a given token value.
 *
 * @example
 * ```ts
 * const formatter = new ShortFormatter();
 * formatter.format(0.000123000050); // outputs: "< 0.01"
 * formatter.format(1001.1); // outputs: "1K"
 * formatter.format(1230000.50); // outputs: "1.2M"
 * formatter.format(17000000000); // outputs: "17B"
 * ```
 */
class ShortFormatter extends Formatter {
  private largeNumberFormatter: Intl.NumberFormat;
  protected boundaries: Boundaries;

  constructor() {
    super();

    // set boundaries
    this.boundaries = {
      sm: new Decimal(1e-2), // 0.01
      lg: new Decimal(1e3), // 1,000
      xxxl: new Decimal(Number.MAX_SAFE_INTEGER),
    };

    // create number formatter for large numbers
    this.largeNumberFormatter = new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
      notation: 'compact',
      style: 'decimal',
    });
  }

  /**
   * Formats a token amount value to be human readable and short
   * @param amount - The amount to format in token units (USDC, DAI, ETH, etc...)
   */
  public format(amount: Amount): string {
    // null checks
    if (!this.boundaries.sm || !this.boundaries.lg || !this.boundaries.xxxl) {
      throw errors.missingBoundaries;
    }

    // variables
    let humanReadable = '';
    const value = new Decimal(amount);

    // formatting logic
    if (value.eq(0)) {
      // zero
      humanReadable = this.toFixedLocaleString(value, 2);
    } else if (value.lt(this.boundaries.sm)) {
      // below 0.01
      humanReadable = `< 0.01`;
    } else if (value.gte(this.boundaries.sm) && value.lt(this.boundaries.lg)) {
      // between 0.01 and 1,000
      humanReadable = this.toFixedLocaleString(value, 2);
    } else if (value.gte(this.boundaries.lg) && value.lte(this.boundaries.xxxl)) {
      // between 1,000 and 9,007,199,254,740,991 inclusive
      humanReadable = this.largeNumberFormatter.format(value.toNumber());
    } else if (value.gt(this.boundaries.xxxl)) {
      // extremely large numbers that Javascript cannot represent, so the
      // currency symbol is prepended to the exponential notation
      humanReadable = this.toExponential(value);
    } else {
      // should never happen
      throw errors.amountOutOfRange;
    }

    return humanReadable;
  }
}

export default ShortFormatter;
