import type { SWRConfiguration } from 'swr';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';

import {
  TokensByAddressesQuery,
  TokensByNameOrSymbolQuery,
  TokensResponse,
} from '../utils/0x/token-registry.types';
import { routes } from '../utils/routes';
import { objectToQueryString } from '../utils/string';
import { getFullURL, handleResponse } from '../utils/swr';
import { SEARCH_V2_COOKIE_KEY, useFeature } from './useFeature';

const DEFAULT_RESULTS_LIMIT = 10;

interface UseSearchTokensParams {
  query?: string;
  address?: string;
  chainIds?: number[];
  limit?: number;
  onSuccess?: () => void;
}

export const useSearchTokens = ({
  address,
  query,
  chainIds,
  limit = DEFAULT_RESULTS_LIMIT,
  onSuccess,
}: UseSearchTokensParams): SWRInfiniteResponse<TokensResponse> => {
  const isV2 = useFeature(SEARCH_V2_COOKIE_KEY); // opensearch
  const getKey = (pageIndex: number) => {
    const canSearch = query || address;
    let parameters: TokensByAddressesQuery | TokensByNameOrSymbolQuery | undefined;

    if (address) {
      parameters = {
        addresses: [address],
        chainId: chainIds,
      };
    } else if (query) {
      parameters = {
        chainId: chainIds,
        limit,
        page: pageIndex,
        query,
      };
    }

    if (!canSearch || !parameters) return;

    return `${routes.api.SEARCH_TOKENS}${objectToQueryString(parameters)}`;
  };

  const fetcher = async (path: string): Promise<TokensResponse> =>
    fetch(getFullURL(path), {
      ...(isV2 ? { headers: { '0x-version': 'v2' } } : {}),
    }).then(handleResponse);

  const config: SWRConfiguration = {
    revalidateIfStale: false, // note: we may want to reenable this in the future, if token data is dynamic.
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  if (onSuccess) config.onSuccess = onSuccess;

  return useSWRInfinite<TokensResponse>(getKey, fetcher, config);
};
