import Image, { ImageProps } from 'next/image';
import React, { useState } from 'react';

import { tokens } from '@/styles/tokens.css';

import FallbackIcon from './fallbackicon';

interface ImageWithFallbackProps extends Omit<ImageProps, 'src'> {
  src?: string;
  useRegularImg?: boolean;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = (props) => {
  const { src, height = 40, width = 40, useRegularImg = false, ...rest } = props;
  const [fallbackNeeded, setFallbackNeeded] = useState(false);

  if (fallbackNeeded || !src) {
    return (
      <span className={props.className} style={{ backgroundColor: tokens.tokenColorPurple900 }}>
        <FallbackIcon height={height} width={width} />
      </span>
    );
  }

  const commonProps = {
    ...rest,
    src,
    height,
    width,
    onError: () => setFallbackNeeded(true),
  };

  if (useRegularImg) {
    /**
     * Currently, we use dynamic image URLs for Solana token logos, so we opt
     * not to optimize them with next/image. If we ever decide to scrape
     * these images into our own services, we can then optimize them with
     * next/image.
     */
    // eslint-disable-next-line @next/next/no-img-element
    return <img {...commonProps} alt={props.alt ?? 'Token Image'} />;
  }

  return <Image {...commonProps} priority loading="eager" alt={props.alt ?? 'Token Image'} />;
};

export default ImageWithFallback;
