import type { Address } from 'viem';

import { Aqua } from './Aqua';
import { Blue } from './Blue';
import { Coral } from './Coral';
import { Green } from './Green';
import { Lime } from './Lime';
import { Magenta } from './Magenta';
import { Navy } from './Navy';
import { Olive } from './Olive';
import { Orange } from './Orange';
import { Purple } from './Purple';
import { Red } from './Red';
import { Seafoam } from './Seafoam';
import { Teal } from './Teal';
import { Turquoise } from './Turquoise';
import { Violet } from './Violet';
import { Yellow } from './Yellow';

interface AvatarKeys {
  /** A base 16 character [0-9a-fA-F]. */
  [key: string]: JSX.Element;
}

/**
 * Map to determine a users avatar icon based on the first letter of their public key.
 * @remarks
 *
 * We should double the colors available in order for every character to have a unique icon.
 */
const avatarKeys: AvatarKeys = {
  0: <Red />,
  1: <Magenta />,
  2: <Orange />,
  3: <Coral />,
  4: <Yellow />,
  5: <Lime />,
  6: <Green />,
  7: <Olive />,
  8: <Turquoise />,
  9: <Seafoam />,
  a: <Blue />,
  b: <Teal />,
  c: <Navy />,
  d: <Aqua />,
  e: <Purple />,
  f: <Violet />,
};

interface AvatarProps {
  /** Base 16 account address (public key). */
  address: Address;
  /** The HTML class of the element. */
  className: string;
}

/** Avatar icon based on first letter the users public key (not including the "0x" prefix).
 */
export const Avatar = ({ address, className }: AvatarProps): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    {avatarKeys[address[2].toLowerCase()]}
  </svg>
);
