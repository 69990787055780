import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { tokenFormatter } from '../../lib/formatting';
import type { Price } from '../../utils/0x.types';
import { Quote } from '../../utils/0x.types';

type QuoteResource = 'swap';
export type CheckoutStep =
  | 'select'
  | 'review'
  | 'pending'
  | 'submitted'
  | 'submitFailed'
  | 'completed'
  | 'priceImpactAlert'
  | 'insufficientBalanceError'
  | 'sellAmountTooSmall'
  | 'catchAllError'
  | 'insufficientAllowanceError'
  | 'insufficientBalanceForFeesError'
  | 'insufficientLiquidityError'
  | 'kError'
  | 'cancelledBeforeSubmissionAlert'
  | 'transactionUnderpricedAlert'
  | 'preSubmitWarnings'
  | 'walletConnectionIssueError';

export interface TokenUsdPrices {
  sellTokenUsd: number;
  buyTokenUsd: number;
  nativeTokenUsd: number;
}

export type WarningPayloads = {
  unfavorableRate: {
    percentDifference: number;
    limitPriceSetFor: 'sell' | 'buy';
  };
};

export interface TokenInputValues {
  decimal: string;
  formatted: string;
  usd: number;
}

export interface SetBalanceResult {
  value: bigint;
  decimals: number;
}

/**
 * Amount and USD price of token being swapped.
 */
interface OrderInput {
  /** Amount of token being swapped. */
  amount: number;
  /** USD price of token being swapped. */
  usdPrice: number;
}
/**
 * Price Impact percent and usd value
 */
export interface PriceImpact {
  /** (buyInputUsdPrice / sellInputUsdPrice - 1) * 100; */
  percent: number;
  /** buyInputUsdPrice - sellInputUsdPrice */
  usd: number;
}

/**
 * Direction of token being swapped: either buy or sell side.
 */
export type TradeDirection = 'buy' | 'sell';

export const DEFAULT_SELL_BUY_INPUT_OBJECT = { usd: 0, decimal: '', formatted: '' } as const;
export const DEFAULT_PRICE_IMPACT_OBJECT = { percent: 0, usd: 0 } as const;

export interface TradeStore {
  sellInput: TokenInputValues;
  buyInput: TokenInputValues;
  isCurrentSwapGasless: boolean;
  priceImpact: PriceImpact;
  directionOfSwap: TradeDirection;
  checkoutQuoteResource?: QuoteResource;
  checkoutStep: CheckoutStep;
  previousCheckoutStep?: CheckoutStep;
  sellTokenBalance?: SetBalanceResult;
  currentPrice?: Price;
  tokenUsdPrices: TokenUsdPrices;
  limitRate: { sell: string; buy: string };
  quote?: Quote;
  preSubmitWarnings: Partial<WarningPayloads>;
  isStableToStablePair: boolean;
  setIsStableToStablePair: (isStableToStablePair: boolean) => void;
  setIsCurrentSwapGasless: (isCurrentSwapGasless: boolean) => void;
  setDirectionOfSwap: (directionOfSwap: TradeDirection) => void;
  updateSellInput: (sellInput?: TokenInputValues, directionOfSwap?: TradeDirection) => void;
  updateSellInputUsd: (usd: number) => void;
  updateBuyInput: (buyInput?: TokenInputValues, directionOfSwap?: TradeDirection) => void;
  updateBuyInputUsd: (usd: number) => void;
  updatePriceImpact: (priceImpact: PriceImpact | undefined) => void;
  error: Error | undefined;
  updateInputs: (
    buyInput?: TokenInputValues,
    sellInput?: TokenInputValues,
    direction?: TradeDirection,
  ) => void;
  switchDirectionOfSwap: () => void;
  updateCheckoutStep: (checkoutStep: CheckoutStep) => void;
  resetCheckoutStep: () => void;
  setResource: (checkoutQuoteResource: QuoteResource | undefined) => void;
  setSellTokenBalance: (sellTokenBalance: SetBalanceResult | undefined) => void;
  setLimitOrder: (sellInput: OrderInput, buyInput: OrderInput, nativeTokenUsdPrice: number) => void;
  setMarketOrder: (
    sellInput: OrderInput,
    buyInput: OrderInput,
    nativeTokenUsdPrice: number,
    direction: TradeDirection,
  ) => void;
  resetCheckout: () => void;
  setTokenUsdPrices: (tokenUsdPrices: TokenUsdPrices) => void;
  setCurrentPrice: (currentPrice?: Price) => void;
  setQuote: (quote: Quote | undefined) => void;
  setError: (error: Error | undefined) => void;
  removePreSubmitWarning: (key: keyof WarningPayloads) => void;
  addPreSubmitWarning: <T extends keyof WarningPayloads>(
    key: T,
    payload: WarningPayloads[T],
  ) => void;
  resetPriceState: () => void;
  resetLimitPrice: () => void;
  setLimitRate: (limitRate: { sell: string; buy: string }) => void;
}

export const useTradeStore = createWithEqualityFn(
  immer<TradeStore>((set, get) => ({
    sellInput: { ...DEFAULT_SELL_BUY_INPUT_OBJECT },
    buyInput: { ...DEFAULT_SELL_BUY_INPUT_OBJECT },
    priceImpact: { ...DEFAULT_PRICE_IMPACT_OBJECT },
    directionOfSwap: 'sell',
    checkoutQuoteResource: undefined,
    checkoutStep: 'select',
    previousCheckoutStep: undefined,
    sellTokenBalance: undefined,
    rate: '',
    limitRate: {
      sell: '',
      buy: '',
    },
    currentPrice: undefined,
    tokenUsdPrices: { sellTokenUsd: 0, buyTokenUsd: 0, nativeTokenUsd: 0 },
    quote: undefined,
    isCurrentSwapGasless: true,
    error: undefined,
    preSubmitWarnings: {},
    isStableToStablePair: false,
    setIsStableToStablePair: (isStableToStablePair: boolean) => {
      set({ isStableToStablePair });
    },
    updateInputs: (
      buyInput?: TokenInputValues,
      sellInput?: TokenInputValues,
      directionOfSwap?: TradeDirection,
    ) => {
      set({ buyInput, sellInput, ...(directionOfSwap && { directionOfSwap }) });
    },
    setIsCurrentSwapGasless: (isCurrentSwapGasless: boolean) => {
      set({ isCurrentSwapGasless });
    },
    setDirectionOfSwap: (directionOfSwap: TradeDirection) => {
      set({ directionOfSwap });
    },
    updateBuyInput: (buyInput?: TokenInputValues, directionOfSwap?: TradeDirection) => {
      set({ buyInput, ...(directionOfSwap && { directionOfSwap }) });
    },
    updateBuyInputUsd: (usd: number) => {
      const { buyInput: currentBuyInput } = get();
      set({
        buyInput: {
          ...currentBuyInput,
          usd,
        },
      });
    },
    updateSellInput: (sellInput?: TokenInputValues, directionOfSwap?: TradeDirection) => {
      set({ sellInput, ...(directionOfSwap && { directionOfSwap }) });
    },
    updateSellInputUsd: (usd: number) => {
      const { sellInput: currentSellInput } = get();
      set({
        sellInput: {
          ...currentSellInput,
          usd,
        },
      });
    },
    updatePriceImpact: (priceImpact: PriceImpact | undefined) => {
      set({ priceImpact });
    },
    updateCheckoutStep: (checkoutStep: CheckoutStep) => {
      set({ checkoutStep, previousCheckoutStep: get().checkoutStep });
    },
    setLimitOrder: (sellInput: OrderInput, buyInput: OrderInput, nativeTokenUsd: number) => {
      set({
        sellInput: {
          decimal: sellInput.amount.toString(),
          formatted: tokenFormatter.format(sellInput.amount, { mode: 'write' }),
          usd: sellInput.usdPrice,
        },
        buyInput: {
          decimal: buyInput.amount.toString(),
          formatted: tokenFormatter.format(buyInput.amount, { mode: 'write' }),
          usd: buyInput.usdPrice,
        },
        isCurrentSwapGasless: false,
        priceImpact: { percent: 0, usd: 0 },
        directionOfSwap: 'sell',
        checkoutQuoteResource: undefined,
        checkoutStep: 'select',
        previousCheckoutStep: undefined,
        sellTokenBalance: undefined,
        currentPrice: undefined,
        tokenUsdPrices: {
          sellTokenUsd: sellInput.usdPrice,
          buyTokenUsd: buyInput.usdPrice,
          nativeTokenUsd,
        },
        quote: undefined,
      });
    },
    setMarketOrder: (
      sellInput: OrderInput,
      buyInput: OrderInput,
      nativeTokenUsd: number,
      direction: TradeDirection,
    ) => {
      set({
        sellInput: {
          decimal: sellInput.amount.toString(),
          formatted: tokenFormatter.format(sellInput.amount, { mode: 'write' }),
          usd: sellInput.usdPrice,
        },
        buyInput: {
          decimal: buyInput.amount.toString(),
          formatted: tokenFormatter.format(buyInput.amount, { mode: 'write' }),
          usd: buyInput.usdPrice,
        },
        isCurrentSwapGasless: true,
        priceImpact: { percent: 0, usd: 0 },
        directionOfSwap: direction,
        checkoutQuoteResource: undefined,
        checkoutStep: 'select',
        previousCheckoutStep: undefined,
        sellTokenBalance: undefined,
        currentPrice: undefined,
        tokenUsdPrices: {
          sellTokenUsd: sellInput.usdPrice,
          buyTokenUsd: buyInput.usdPrice,
          nativeTokenUsd,
        },
        quote: undefined,
      });
    },
    resetCheckoutStep: () => {
      set({ checkoutStep: 'select', previousCheckoutStep: undefined, error: undefined });
    },
    switchDirectionOfSwap: () => {
      const { sellInput, buyInput, directionOfSwap, tokenUsdPrices } = get();
      set({
        sellInput: buyInput,
        buyInput: sellInput,
        directionOfSwap: directionOfSwap === 'buy' ? 'sell' : 'buy',
        tokenUsdPrices: {
          nativeTokenUsd: tokenUsdPrices.nativeTokenUsd,
          buyTokenUsd: tokenUsdPrices.sellTokenUsd,
          sellTokenUsd: tokenUsdPrices.buyTokenUsd,
        },
        priceImpact: DEFAULT_PRICE_IMPACT_OBJECT,
      });
    },
    setResource(checkoutQuoteResource: QuoteResource | undefined) {
      set({ checkoutQuoteResource });
    },
    setSellTokenBalance(sellTokenBalance: SetBalanceResult | undefined) {
      set({ sellTokenBalance });
    },
    setTokenUsdPrices(tokenUsdPrices: TokenUsdPrices) {
      set({ tokenUsdPrices });
    },
    setCurrentPrice(currentPrice: Price | undefined) {
      set({ currentPrice });
    },
    setQuote(quote: Quote | undefined) {
      set({ quote });
    },
    setError: (error: Error | undefined) => {
      set({ error });
    },
    resetCheckout() {
      set({
        sellInput: { ...DEFAULT_SELL_BUY_INPUT_OBJECT },
        buyInput: { ...DEFAULT_SELL_BUY_INPUT_OBJECT },
        priceImpact: { ...DEFAULT_PRICE_IMPACT_OBJECT },
        directionOfSwap: 'sell',
        checkoutStep: 'select',
        previousCheckoutStep: undefined,
        checkoutQuoteResource: undefined,
        quote: undefined,
        error: undefined,
      });
    },
    removePreSubmitWarning: (key: keyof WarningPayloads) => {
      set((state) => {
        delete state.preSubmitWarnings[key];
      });
    },
    addPreSubmitWarning: <T extends keyof WarningPayloads>(key: T, payload: WarningPayloads[T]) => {
      set((state) => {
        state.preSubmitWarnings[key] = payload;
      });
    },
    resetPriceState() {
      set({
        sellInput: { ...DEFAULT_SELL_BUY_INPUT_OBJECT },
        buyInput: { ...DEFAULT_SELL_BUY_INPUT_OBJECT },
        priceImpact: { ...DEFAULT_PRICE_IMPACT_OBJECT },
        currentPrice: undefined,
        tokenUsdPrices: { sellTokenUsd: 0, buyTokenUsd: 0, nativeTokenUsd: 0 },
        quote: undefined,
        error: undefined,
        preSubmitWarnings: {},
      });
    },
    setLimitRate(limitRate: { sell: string; buy: string } | undefined) {
      set({ limitRate });
    },
    resetLimitPrice() {
      const { setLimitRate } = get();
      setLimitRate({ sell: '', buy: '' });
    },
  })),
  shallow,
);

export const tradeHandlers = (state: TradeStore) => ({
  resetCheckout: state.resetCheckout,
  updateCheckoutStep: state.updateCheckoutStep,
  resetCheckoutStep: state.resetCheckoutStep,
  previousCheckoutStep: state.previousCheckoutStep,
});

/* Individual Selectors */
// Checkout Selectors
export const checkoutStepSelector = (state: TradeStore) => state.checkoutStep;
export const resetCheckoutSelector = (state: TradeStore) => state.resetCheckout;
export const isStableToStablePairSelector = (state: TradeStore) => state.isStableToStablePair;
export const updateCheckoutStepSelector = (state: TradeStore) => state.updateCheckoutStep;
export const resetCheckoutStepSelector = (state: TradeStore) => state.resetCheckoutStep;
export const directionOfSwapSelector = (state: TradeStore) => state.directionOfSwap;
export const tokenUsdPricesSelector = (state: TradeStore) => state.tokenUsdPrices;
export const resetPriceStateSelector = (state: TradeStore) => state.resetPriceState;
export const sellInputSelector = (state: TradeStore) => state.sellInput;
export const updateBuyInputSelector = (state: TradeStore) => state.updateBuyInput;
export const updateSellInputSelector = (state: TradeStore) => state.updateSellInput;
export const updateInputsSelector = (state: TradeStore) => state.updateInputs;
export const currentPriceSelector = (state: TradeStore) => state.currentPrice;
export const isCurrentSwapGaslessSelector = (state: TradeStore) => state.isCurrentSwapGasless;
export const errorSelector = (state: TradeStore) => state.error;
export const priceImpactSelector = (state: TradeStore) => state.priceImpact;

export const preSubmitWarningSelectors = (state: TradeStore) => ({
  preSubmitWarnings: state.preSubmitWarnings,
  addPreSubmitWarning: state.addPreSubmitWarning,
  removePreSubmitWarning: state.removePreSubmitWarning,
});

export const resetFunctionsSelector = (state: TradeStore) => ({
  resetCheckout: state.resetCheckout,
  resetCheckoutStep: state.resetCheckoutStep,
  resetPriceState: state.resetPriceState,
  resetLimitPrice: state.resetLimitPrice,
});

export const stableToStablePairSelectors = (state: TradeStore) => ({
  isStableToStablePair: state.isStableToStablePair,
  setIsStableToStablePair: state.setIsStableToStablePair,
});
