import { z } from 'zod';

import { WRAPPED_NATIVE_ADDRESSES } from '@/constants/addresses';
import { solana } from '@/constants/chain';

import { ChainAddress } from '../models';
import {
  getTokensByAddressSchema,
  getTokensByAddressesSchema,
  getTokensByQuerySchema,
} from './token-registry.schema';

interface ExchangeList {
  /** Name of exchange */
  name: string;
  /** Name of the token in this List. null if not available */
  token_name: string | null;
}

interface TokenList extends ExchangeList {
  /** URL of the token list */
  url: string;
}

export interface TokenResult {
  /** Token contract address */
  address: ChainAddress;
  /** Token's network ID */
  chainId: number;
  /** Token's network name */
  chainName: string;
  /** Number of decimals allowed in token amount */
  decimals: number;
  /** List of exchanges that trade this token */
  exchangeLists: ExchangeList[] | null;
  /** Token's logo */
  logo: string | null;
  /** Token's name */
  name: string;
  /** Errors */
  otherErrors: any | null;
  /** Prominent color of the token's logo */
  prominentColor: string | null;
  /** Token's symbol */
  symbol: string;
  /** Token lists that include this token */
  tokenLists: TokenList[];
  /** Blocked token lists that include this token */
  tokenListsBlock: TokenList[];
  /** Warning token lists that include this token */
  tokenListsFlag: TokenList[];
  /** Type of token (e.g. ERC20) */
  type: string;
  /** Is this token native USDC - note: this value is defined by us in the api route */
  isNativeUsdc?: boolean;
  /** Whether the token is identified as a honeypot scam. "HoneyPot" means that the token
   * maybe cannot be sold because of the token contract's function, or the token contains
   * malicious code. When isOpenSource returns false, this field will be null. */
  isHoneypot: boolean | null;
  /** Whether the token is a counterfeit of a mainstream asset. When there is no evidence
   * indicating that it is a counterfeit asset, there will be no return value. */
  fakeToken: boolean | null;
  /** Whether the token contract has unusual gas consumption. Any interaction with such
   * tokens may result in loss of property. Returns true if the contract is using the user's gas
   * fee to mint other assets. No return (null) means there is no evidence of gas abuse. */
  gasAbuse: boolean | null;
  /** Whether the token is identified as an airdrop scam */
  isAirdropScam: boolean | null;
  /** Whether the token's contract source code is open sourced */
  isOpenSource: boolean | null;
  /** Whether the contract can self-destruct */
  selfDestruct: boolean | null;
  /** Whether the token is included in trusted token lists. No return
   * doesn't necessarily mean it is risky, but that no conclusive information
   * was found.
   */
  trustList: boolean | null;
  /** Tax percentage applied when buying the token. This value is returned
   * on a scale of 0-1, where 1 is 100% tax.
   */
  buyTax: number | null;
  /** Tax percentage applied when selling the token. This value is returned
   * on a scale of 0-1, where 1 is 100% tax. */
  sellTax: number | null;
  /** Whether the contract has the function to modify the maximum amount
   * of transactions or the maximum token position.
   * When isOpenSource is false & if unknown, this value is expected to be null. */
  antiWhaleModifiable: boolean | null;
  /** Whether the token can have ownership recovered/taken back.
   * Ownership is usually used to adjust the parameters and status of the contract,
   * such as minting, modification of slippage, suspension of trading, setting
   * blacklist, etc. When the contract's owner cannot be retrieved, it is a black
   * hole address, or does not have an owner, therefore, ownership-related functionality
   * will most likely be disabled. These risky functions may be able to be reactivated
   * if ownership is reclaimed.
   * When isOpenSource is false & if unknown, this value is expected to be null.
   */
  canTakeBackOwnership: boolean | null;
  /** Whether buying the token is restricted.  */
  cannotBuy: boolean | null;
  /** Whether there are restrictions on selling entire token balance */
  cannotSellAll: boolean | null;
  /** Whether the contract makes external calls to other contracts during the execution
   * of primary methods. Will return no data (null) if isOpenSource is false.
   */
  externalCall: boolean | null;
  /** Whether the contract has hidden owners. */
  hiddenOwner: boolean | null;
  /** Whether the contract has the function to limit the maximum amount of transactions
   * or the maximum token position for a single address. */
  isAntiWhale: boolean | null;
  /** Whether new tokens can be minted.  Mint functions can trigger a massive sell-off,
   * causing the coin price to plummet. It is considered an extremely risky function
   * or a contract to have. */
  isMintable: boolean | null;
  /** Whether the contract is a proxy contract. Most proxy contracts are accompanied by
   * implementation contracts which are modifiable, potentially containing significant risk.
   * When the contract is a proxy, other risk items may not be returned. */
  isProxy: boolean | null;
  /** Whether the blacklist function is not included in the contract. If there is a blacklist,
   * some addresses may not be able to trade normally. */
  isBlacklisted: boolean | null;
  /** Whether the whitelist function is not included in the contract. If there is a whitelist,
   *  some addresses may not be able to trade normally. */
  isWhitelisted: boolean | null;
  /** Whether the owner can set a different tax rate for every assigned address. If true, The contract
   * owner may set a very outrageous tax rate for an assigned address to block it from trading.
   * Abuse of this function will lead to great risks. */
  personalSlippageModifiable: boolean | null;
  /** Whether the trading tax can be modified by the token contract. Token with modifiable tax means
   * that the contract owner can modify the buy tax or sell tax of the token. This may cause some losses,
   * especially since some contracts have unlimited modifiable tax rates, which would make the token
   * untradeable. */
  slippageModifiable: boolean | null;
  /** Whether the contract has a trading-cool-down mechanism that can limit the minimum time between
   * two transactions. */
  tradingCooldown: boolean | null;
  /** Whether trading can be pausable by the token contract. */
  transferPausable: boolean | null;
  /** Address that created the token contract */
  creatorAddress: ChainAddress | '' | null;
  /** Current owner address of the token contract */
  ownerAddress: ChainAddress | '' | null;
  /** Number of unique addresses holding the token */
  holders: number | null;
  /** Ratio of total supply held by top 10 holders. This value is returned
   * on a scale of 0-1, where 1 is 100% of the total supply.
   */
  top10HoldersRatio: number | null;
}

export interface TokensData {
  data: TokenResult[];
  resultCount: number;
}

interface TokensError {
  code: string;
  message: string;
}

export type TokensResponse = TokensData | TokensError;

export type TokensByAddressQuery = z.infer<typeof getTokensByAddressSchema>;

export type TokensByAddressesQuery = z.infer<typeof getTokensByAddressesSchema>;

export type TokensByNameOrSymbolQuery = z.infer<typeof getTokensByQuerySchema>;

/**
 * Sometimes we encounter Solana tokens that aren’t in the TokenResult format
 * (e.g., Jupiter’s token list, Codex trending token). This function converts
 * them to the TokenResult format used by Matcha, and with the new search, we
 * aim to standardize our backend schema.
 */
export function mapSolanaTokensToTokenResults(tokens: any[]): TokenResult[] {
  return tokens.map((token) => {
    if (!token.address) {
      console.warn('Missing required values for token:', token);
      throw new Error('Missing required values for token.');
    }

    return {
      address: token.address,
      chainId: token.chainId || solana.id,
      chainName: 'Solana',
      decimals: token.decimals,
      exchangeLists: null,
      logo: token.logo ?? token.logoURI ?? null,
      name:
        token.address === WRAPPED_NATIVE_ADDRESSES[solana.id]
          ? solana.nativeCurrency.name
          : token.name,
      otherErrors: null,
      prominentColor: null,
      symbol: token.symbol,
      tokenLists: [],
      tokenListsBlock: [],
      tokenListsFlag: [],
      type: 'SPL',
      isHoneypot: null,
      fakeToken: null,
      gasAbuse: null,
      isAirdropScam: null,
      isOpenSource: null,
      selfDestruct: null,
      trustList: null,
      buyTax: null,
      sellTax: null,
      antiWhaleModifiable: null,
      canTakeBackOwnership: null,
      cannotBuy: null,
      cannotSellAll: null,
      externalCall: null,
      hiddenOwner: null,
      isAntiWhale: null,
      isMintable: null,
      isProxy: null,
      isBlacklisted: null,
      isWhitelisted: null,
      personalSlippageModifiable: null,
      slippageModifiable: null,
      tradingCooldown: null,
      transferPausable: null,
      creatorAddress: null,
      ownerAddress: null,
      holders: null,
      top10HoldersRatio: null,
    };
  });
}
