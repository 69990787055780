import { classNames } from '../../../utils/classnames';
import { loadingSpinnerPrimaryStyles, loadingSpinnerStyles } from './LoadingSpinner.css';

interface Props {
  className?: string;
  variant?: 'white' | 'black';
}

export const LoadingSpinner = ({ className, variant = 'black' }: Props) => {
  return (
    <span
      className={
        className ? `${className} ${loadingSpinnerStyles[variant]}` : loadingSpinnerStyles[variant]
      }
    />
  );
};

export const LoadingSpinnerPrimary = ({ className, variant = 'black' }: Props) => {
  return <span className={classNames(className, loadingSpinnerPrimaryStyles[variant])} />;
};
