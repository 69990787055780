import { NATIVE_USDC_HELP_TEXT } from '@/hooks/useCustomContent';

import { Badge } from '@/ui/Badge';
import {
  MatchaTooltip,
  MatchaTooltipBottomSheet,
  MatchaTooltipContent,
  MatchaTooltipPopover,
  MatchaTooltipTrigger,
} from '@/ui/MatchaTooltip';

import { tooltipPopoverClass } from './index.css';

interface IssuedByCircleBadgeProps {
  className?: string;
  clickable?: boolean;
}

const IssuedByCircleBadge = ({ className, clickable }: IssuedByCircleBadgeProps) => {
  return (
    <MatchaTooltip>
      <MatchaTooltipTrigger>
        <span className={className}>
          <Badge
            role="tooltip"
            variant="usdc"
            aria-label="Issued by Circle"
            aria-describedby="nativeUsdcHelpText"
            style={clickable ? { cursor: 'pointer' } : {}}
          >
            Issued by Circle
          </Badge>
        </span>
      </MatchaTooltipTrigger>
      <MatchaTooltipContent>
        <span id="nativeUsdcHelpText">{NATIVE_USDC_HELP_TEXT}</span>
      </MatchaTooltipContent>
      <MatchaTooltipPopover sideOffset={8} className={tooltipPopoverClass} />
      <MatchaTooltipBottomSheet header="Issued by Circle" />
    </MatchaTooltip>
  );
};

export default IssuedByCircleBadge;
