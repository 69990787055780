import {
  ETH_FAKE_ADDRESS,
  MATIC_ADDRESS_MAINNET,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID,
  WRAPPED_NATIVE_ADDRESSES,
} from '../constants/addresses';
import { CHAIN_IDS, SUPPORTED_CHAINS } from '../constants/chain';
import { Currency, WRAPPED_NATIVE_TOKENS } from '../constants/currency';
import { TokenResult } from './0x/token-registry.types';
import { ChainAddress } from './models';
import { TokenInfo } from './token.types';

const POL_ADDRESS_MATIC = '0x0000000000000000000000000000000000001010';

export const isTokenAddressNativeAsset = (
  tokenAddress: string | undefined,
  chainId: number | undefined,
) => {
  if (!tokenAddress || !chainId) return undefined;

  return (
    tokenAddress.toLowerCase() === NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[chainId] ||
    (chainId === CHAIN_IDS.MATIC && tokenAddress.toLowerCase() === POL_ADDRESS_MATIC)
  );
};

export const isTokenInfoNativeAsset = (token: TokenInfo | undefined) =>
  isTokenAddressNativeAsset(token?.address, token?.chainId);

export const isTokenResultNativeAsset = (token: TokenResult | undefined) =>
  isTokenAddressNativeAsset(token?.address, token?.chainId);

export const doesTokenNeedSellApproval = (
  tokenAddress: string | undefined,
  chainId: number | undefined,
) => {
  if (!tokenAddress || !chainId) {
    return undefined;
  }
  // On Ethereum, the native token does not need approval
  return tokenAddress.toLowerCase() !== ETH_FAKE_ADDRESS.toLowerCase();
};

export const isTokenAddressWrappedNativeAsset = (
  tokenAddress: string | undefined,
  chainId: number | undefined,
): boolean | undefined => {
  if (!tokenAddress) return undefined;
  if (!chainId) return undefined;

  return tokenAddress.toLowerCase() === WRAPPED_NATIVE_ADDRESSES[chainId];
};

// Sometimes coingecko does not allow looking up the 'native' token for a chain, and forces you to reference it on mainnet as an ERC20 (e.g. MATIC)
export const getContractAddressOfNativeTokenOnEthereum = (chainId: number) => {
  if (chainId === CHAIN_IDS.BSC) {
    // Not working for some reason
    // return '0xB8c77482e45F1F44dE1745F52C74426C631bDD52'.toLowerCase();
    return undefined;
  } else if (chainId === CHAIN_IDS.MATIC) {
    return MATIC_ADDRESS_MAINNET;
  }
  return undefined;
};

export const getNativeAddress = (chainId: number) => {
  return NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[chainId];
};

export const getNativeAddressFromSymbol = (
  symbol: string | undefined,
  chainId: number | undefined,
): ChainAddress | undefined => {
  if (!symbol) return;

  for (let i = 0; i < SUPPORTED_CHAINS.length; i++) {
    const currentChain = SUPPORTED_CHAINS[i];
    if (
      symbol.toLowerCase() === currentChain.nativeCurrency?.symbol.toLowerCase() &&
      chainId === currentChain.id
    ) {
      const address = NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[currentChain.id];
      return address;
    }
  }
};

export const getWrappedNativeCurrencyForChainId = (chainId: number): Currency => {
  const token = WRAPPED_NATIVE_TOKENS[chainId];
  if (token) return token;

  throw new Error(`Chain id ${chainId} does not have a wrapped native token reference available`);
};
