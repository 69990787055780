import { useListBoxSection } from '@react-aria/listbox';
import { useSeparator } from '@react-aria/separator';
import { ComboBoxState } from '@react-stately/combobox';

import Option from '../Option';
import { headerContainerClass } from './index.css';

interface Props {
  section: any;
  state: ComboBoxState<{}>;
}

export default function ListBoxSection({ section, state }: Props) {
  let { itemProps, headingProps, groupProps } = useListBoxSection({
    'heading': section.rendered,
    'aria-label': section['aria-label'],
  });

  let { separatorProps } = useSeparator({
    elementType: 'li',
  });

  // If the section is not the first, add a separator element.
  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <>
      {section.key !== state.collection.getFirstKey() && <li {...separatorProps} />}
      <li {...itemProps}>
        {section.rendered && (
          <div {...headingProps} className={headerContainerClass}>
            {section.rendered}
          </div>
        )}
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  );
}
