import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export interface AccountSettingsByAddress {
  [address: string]: AccountSettings;
}

interface AccountSettings {
  name?: string;
}

interface AccountStore {
  settings: AccountSettingsByAddress;
  setSettings: (settings: AccountSettings, address: string) => void;
}

export const useAccountStore = createWithEqualityFn<AccountStore>()(
  persist(
    (set, get) => ({
      settings: {},
      setSettings: (settings: AccountSettings, address: string) =>
        set({
          settings: {
            ...get().settings,
            [address]: { ...(get().settings[address] || {}), ...settings },
          },
        }),
    }),
    {
      name: 'account-storage',
    },
  ),
  shallow,
);

export const accountSettingsSelectors = (state: AccountStore) => ({
  settings: state.settings,
  setSettings: state.setSettings,
});
