import type { TokenResult, TokensResponse } from './0x/token-registry.types';

// Join the search results from multiple pages into a single array of unique tokens
export const joinSearchResults = (searchResultsPaginated: TokensResponse[]) => {
  const map = new Map<string, TokenResult>();

  searchResultsPaginated.forEach((page: TokensResponse) => {
    if ('data' in page && page.data) {
      page.data.forEach((token: TokenResult) => {
        map.set(`${token.chainId}-${token.address}`, token);
      });
    }
  });

  return Array.from(map.values());
};
