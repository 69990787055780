import { fetchDigitalAsset } from '@metaplex-foundation/mpl-token-metadata';
import { publicKey } from '@metaplex-foundation/umi';
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { web3JsRpc } from '@metaplex-foundation/umi-rpc-web3js';
import { Item, Section } from '@react-stately/collections';
import { useQuery } from '@tanstack/react-query';
import type { MouseEvent } from 'react';
import ContentLoader from 'react-content-loader';
import { Address } from 'viem';

import { solana } from '@/constants/chain';

import { tokens } from '@/styles/tokens.css';
import { fontStyles } from '@/styles/typography.css';

import { ChevronDownIcon } from '@/ui/Icons';
import { LoadingSpinner } from '@/ui/Icons/Animated/LoadingSpinner';

import { getSolanaConnection } from '@/utils/solana';

import {
  clearButtonClass,
  moreContainerClass,
  moreIconContainerClass,
  sectionContentLoaderClass,
  sectionTitleClass,
  showMoreLabel,
} from './index.css';
import SearchComboBoxItem, { SearchComboBoxItemProps } from './SearchComboBoxItem';

const SHOW_RECENT_RESULTS_COUNT = 4;

export const SEARCH_SECTION_LOAD_MORE_PREFIX = 'loadMore';
export const SEARCH_SECTION_TYPE_BALANCES = 'balances';
export const SEARCH_SECTION_TYPE_RESULTS = 'searchResults';
export const SEARCH_SECTION_TYPE_TRENDING_TOKENS = 'trendingTokens';
export const SEARCH_SECTION_TYPE_RECENTLY_VIEWED = 'recentlyViewed';

export type SEARCH_SECTION_ID =
  | 'loadMore'
  | 'balances'
  | 'searchResults'
  | 'trendingTokens'
  | 'recentlyViewed';

const loadingCells = new Array(8).fill(1);

const LoadingMore = () => (
  <div className={moreContainerClass}>
    <div className={moreIconContainerClass}>
      <LoadingSpinner variant="black" />
    </div>
    <span className={fontStyles.BODY_MD}>Loading more...</span>
  </div>
);

const ShowMore = () => (
  <div className={moreContainerClass}>
    <div className={moreIconContainerClass}>
      <ChevronDownIcon />
    </div>
    <span className={showMoreLabel}>Show more</span>
  </div>
);

const getItemKeyFromToken = (address: Address | string, chainId: number, sectionId: string) =>
  `${chainId}-${address}-${sectionId}`;

const getItemFromToken = (token: SearchComboBoxItemProps, sectionId: string) => {
  const key = getItemKeyFromToken(token.address, token.chainId, sectionId);
  const commonProps = {
    name: token.name,
    symbol: token.symbol,
    chainId: token.chainId,
    address: token.address,
    balance: token.balance,
    balanceUsd: token.balanceUsd,
    isNativeUsdc: token.isNativeUsdc,
  };

  return (
    <Item key={key} textValue={token.name}>
      {token.chainId === solana.id && token.logoUrl === undefined ? (
        <SolanaTokenLogoProvider token={token}>
          {(url) => <SearchComboBoxItem {...commonProps} logoUrl={token.logoUrl || url} />}
        </SolanaTokenLogoProvider>
      ) : (
        <SearchComboBoxItem {...commonProps} logoUrl={token.logoUrl} />
      )}
    </Item>
  );
};

interface SearchSectionArgs {
  hasMoreTokensWithBalances?: boolean;
  hasMoreTrendingTokens: boolean;
  hasMoreSearchResults: boolean;
  isLoadingMoreSearchResults: boolean;
  onClearRecent?: (e: MouseEvent<HTMLButtonElement>) => void;
  recentResults?: SearchComboBoxItemProps[];
  searchResults: SearchComboBoxItemProps[] | undefined;
  tokensWithBalances?: SearchComboBoxItemProps[] | undefined;
  trendingTokens: SearchComboBoxItemProps[] | undefined;
}

/**
 * Create an array of token result sections
 *
 * @remarks
 *
 * Based on the results provided, includes sections for:
 * - Token balances
 * - Recent results
 * - Trending tokens (default screen when there are no search results)
 * - Search results
 *
 * @param props - props for the functions components
 * @returns `JSX.Element[]`
 */
export const getSections = ({
  hasMoreTokensWithBalances,
  hasMoreTrendingTokens,
  hasMoreSearchResults,
  isLoadingMoreSearchResults,
  onClearRecent,
  recentResults,
  searchResults,
  tokensWithBalances,
  trendingTokens,
}: SearchSectionArgs) => {
  const sections = [];

  /** Add the 'tokens with balances' section. */
  if (!searchResults && tokensWithBalances && tokensWithBalances.length > 0) {
    // Tokens with balances
    const elements = tokensWithBalances.map((token) =>
      getItemFromToken(token, SEARCH_SECTION_TYPE_BALANCES),
    );

    if (hasMoreTokensWithBalances) {
      // add show more button
      elements.push(
        <Item
          key={`${SEARCH_SECTION_LOAD_MORE_PREFIX}-${SEARCH_SECTION_TYPE_BALANCES}`}
          textValue="Show more"
        >
          <ShowMore />
        </Item>,
      );
    }

    sections.push(
      <Section key="my-tokens" title="My Tokens">
        {elements}
      </Section>,
    );
  }

  if (!!recentResults && recentResults.length > 0) {
    // Recently viewed tokens
    sections.push(
      <Section
        key={SEARCH_SECTION_TYPE_RECENTLY_VIEWED}
        title={
          <div className={sectionTitleClass}>
            <span>Recent</span>
            {onClearRecent && (
              <button className={clearButtonClass} onClick={onClearRecent} type="button">
                Clear
              </button>
            )}
          </div>
        }
      >
        {recentResults
          .slice(0, SHOW_RECENT_RESULTS_COUNT)
          .map((token) => getItemFromToken(token, SEARCH_SECTION_TYPE_RECENTLY_VIEWED))}
      </Section>,
    );
  }

  if (searchResults) {
    // Search results
    const elements = searchResults.map((token) =>
      getItemFromToken(token, SEARCH_SECTION_TYPE_RESULTS),
    );

    if (hasMoreSearchResults) {
      // add show more button
      elements.push(
        <Item
          key={`${SEARCH_SECTION_LOAD_MORE_PREFIX}-${SEARCH_SECTION_TYPE_RESULTS}`}
          textValue="Show more"
        >
          {isLoadingMoreSearchResults ? <LoadingMore /> : <ShowMore />}
        </Item>,
      );
    }

    sections.push(
      <Section key="search-results" title="Results">
        {elements}
      </Section>,
    );
  } else if (trendingTokens) {
    // Trending/Popular tokens
    const elements = trendingTokens.map((token) =>
      getItemFromToken(token, SEARCH_SECTION_TYPE_TRENDING_TOKENS),
    );

    if (hasMoreTrendingTokens) {
      // add show more button
      elements.push(
        <Item
          key={`${SEARCH_SECTION_LOAD_MORE_PREFIX}-${SEARCH_SECTION_TYPE_TRENDING_TOKENS}`}
          textValue="Show more"
        >
          <ShowMore />
        </Item>,
      );
    }

    sections.push(
      <Section key={SEARCH_SECTION_TYPE_TRENDING_TOKENS} title="Trending">
        {elements}
      </Section>,
    );
  } else {
    sections.push(
      <Section key="loading-trending-tokens" title="Trending">
        {loadingCells.map((_, index) => {
          return (
            <Item key={`loading-${index}-trendingTokens`} textValue="Show more">
              <ContentLoader
                className={sectionContentLoaderClass}
                width="100%"
                height="56px"
                uniqueKey="tokenSearchCellContentLoader"
                foregroundColor={tokens.tokenColorGray400}
                backgroundColor={tokens.tokenColorGray200}
              >
                <circle cx="28" cy="28" r="16" />
                <circle cx="40" cy="40" r="6" strokeWidth="2" stroke="white" />
                <rect x="56" y="12" width="212" height="14" rx="4" />
                <rect x="56" y="31" width="80" height="14" rx="4" />
              </ContentLoader>
            </Item>
          );
        })}
      </Section>,
    );
  }

  return sections;
};

function SolanaTokenLogoProvider({
  token,
  children,
}: {
  token: { address: string };
  children: (url: string) => JSX.Element;
}) {
  const { data: logoUrl } = useQuery({
    queryKey: [token.address],
    queryFn: async () => {
      const connection = await getSolanaConnection();
      const umi = createUmi(connection).use(web3JsRpc(connection));
      const data = await fetchDigitalAsset(umi, publicKey(token.address));
      const response = await fetch(data.metadata.uri);
      const metadata = await response.json();

      return metadata ? metadata.image : undefined;
    },
  });

  return children(logoUrl);
}
