import { useEffect, useRef } from 'react';
import { useMedia } from 'react-use';

import { breakpoints } from '../styles/util';

/**
 * This hook is used as a workaround for applying styles to the `<ul>` elements in section because the `<Section>` componenst from
 * `@react-stately/collections` does not forward HTML prop, and thus cannot be styled. Custom styles are necessary to make
 * the search modal content scrollable on mobile.
 *
 * @returns A ref that can be appled to a container which targets react-aria <Section> components.
 *
 */
export function useTokenSectionStyles() {
  const isMobile = useMedia(breakpoints.mobile, false);
  const tokenSectionContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tokenSectionContainerRef.current) {
      const listbox: HTMLUListElement | null =
        tokenSectionContainerRef.current.querySelector('[role="listbox"]');

      if (listbox && !listbox.style.maxHeight) {
        const ulRect = listbox.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        const distanceFromTopToBottomOfViewport = viewportHeight - ulRect.top;

        listbox.style.maxHeight = `${distanceFromTopToBottomOfViewport}px`;
        listbox.style.overflowY = 'scroll';
      }
    }
  });

  return isMobile ? tokenSectionContainerRef : undefined;
}
