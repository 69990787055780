import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  useConnectWallet,
  type ConnectedWallet,
  type ConnectedSolanaWallet,
} from '@privy-io/react-auth';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { GetEnsNameReturnType } from 'viem';
import { useChainId, useEnsName } from 'wagmi';

import { tokenFormatter, usdFormatter } from '@/lib/formatting';

import { Web3WalletIcon } from '@/components/Web3WalletIcon';

import { WRAPPED_NATIVE_ADDRESSES } from '@/constants/addresses';
import { NATIVE_TOKEN_SYMBOL_PER_CHAIN, solana } from '@/constants/chain';

import { useMatchaWallets } from '@/hooks/useMatchaWallets';
import { useUSDPrices } from '@/hooks/useUSDPrices';

import {
  AccountSettingsByAddress,
  accountSettingsSelectors,
  useAccountStore,
} from '@/store/account';
import { nativeTokenBalanceSelectors, useBalanceStore } from '@/store/balance';

import { btnStyles } from '@/styles/buttons.css';
import { tokens } from '@/styles/tokens.css';

import { ChevronDownIcon, DisconnectIcon } from '@/ui/Icons';
import Tooltip from '@/ui/Tooltip';

import { formatShortAddress } from '@/utils/address';
import { EVENT_NAME, logEvent } from '@/utils/amplitude';
import { EthereumAddress, SolanaAddress } from '@/utils/models';
import { getDisconnectedKey } from '@/utils/solana';
import { truncate } from '@/utils/string';

import {
  accountButtonClass,
  walletIconOverride,
  chevronDownClass,
  accountButtonContentClass,
  accountButtonNameClass,
} from '../AccountButtonDropdown/index.css';
import {
  accountPopoverClass,
  addressClass,
  balanceContainerClass,
  balanceEmphasisedTextClass,
  balanceMutedTextClass,
  ctaButtonClass,
  disconnectButtonClass,
  itemClass,
  walletAndChainOverrideClass,
  walletInformationClass,
} from './index.css';

const AccountButtonDropdownSolana = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { connectWallet } = useConnectWallet({
    onSuccess: ({ wallet }) => {
      localStorage.removeItem(getDisconnectedKey(wallet));
    },
    onError: () => {
      logEvent({ name: EVENT_NAME.CONNECT_WALLET_ERROR });
    },
  });
  const { solanaWallet, ethereumWallet } = useMatchaWallets();
  const evmChainId = useChainId();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const connectButtonRef = useRef<HTMLButtonElement>(null);

  const activeWallets = useMemo<(ConnectedSolanaWallet | ConnectedWallet)[]>(
    () => [ethereumWallet, solanaWallet].filter((val) => val !== undefined),
    [solanaWallet, ethereumWallet],
  );

  useEffect(() => {
    if (selectedOptionIndex === activeWallets.length) {
      connectButtonRef.current?.focus();
    }
  }, [activeWallets, selectedOptionIndex]);

  const { nativeTokenBalances } = useBalanceStore(nativeTokenBalanceSelectors);
  const onDisconnect = useCallback((wallet: ConnectedWallet | ConnectedSolanaWallet) => {
    wallet.disconnect();
    logEvent({ name: EVENT_NAME.CLICK_CONNECT_DISCONNECT_WALLET });
  }, []);

  const onConnect = () => {
    connectButtonRef.current?.blur();
    connectWallet();
    setTimeout(() => {
      // focus the modal once the modal is animated in
      (
        document.querySelector(
          '#privy-modal-content button.login-method-button:first-child',
        ) as HTMLButtonElement
      )?.focus();
    }, 200);
    // we need to close the dropdown to allow the privy modal becoming scrollable
    setOpen(false);
    setSelectedOptionIndex(-1);
  };

  return (
    <>
      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <DropdownTrigger onConnect={onConnect} />

        <DropdownMenu.Portal>
          <DropdownMenu.Content align="end" className={accountPopoverClass}>
            {activeWallets.map((wallet, index) => (
              <DropdownMenuItem
                key={wallet.address}
                wallet={wallet}
                selected={selectedOptionIndex === index}
                onPrevious={() => {
                  if (index === 0) {
                    // One of the options is the connect button, so we want to allow length + 1
                    setSelectedOptionIndex(activeWallets.length);
                  } else {
                    setSelectedOptionIndex(Math.max(0, index - 1));
                  }
                }}
                onNext={() => {
                  // One of the options is the connect button, so we want to allow length + 1
                  setSelectedOptionIndex(Math.min(activeWallets.length, index + 1));
                }}
                onDisconnect={onDisconnect}
                balance={nativeTokenBalances[wallet.type === 'solana' ? 'sol' : 'evm']}
                chainId={wallet.type === 'solana' ? solana.id : evmChainId}
              />
            ))}
            <div>
              <button
                tabIndex={selectedOptionIndex === activeWallets.length ? 0 : -1}
                onClick={onConnect}
                style={{ width: '100%' }}
                className={ctaButtonClass}
                ref={connectButtonRef}
                onKeyDown={(event) => {
                  if (event.key === 'ArrowDown') {
                    setSelectedOptionIndex(0);
                  } else if (event.key === 'ArrowUp') {
                    setSelectedOptionIndex(Math.max(selectedOptionIndex - 1, 0));
                  } else if (event.key === 'Enter') {
                    onConnect();
                  }
                }}
              >
                {solanaWallet && ethereumWallet
                  ? 'Switch to another wallet'
                  : 'Connect another wallet'}
              </button>
            </div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default AccountButtonDropdownSolana;

function ShortenedAddress({
  wallet,
  isEthereum,
}: {
  wallet?: ConnectedWallet | ConnectedSolanaWallet;
  isEthereum: boolean;
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [clickedButton, setClickedButton] = useState(false);
  const onCopyAddress = useCallback(() => {
    if (!wallet) return;
    navigator.clipboard.writeText(wallet.address);
    setClickedButton(true);
    setTimeout(() => {
      setShowTooltip(false);

      // re-enabling interactivity after tooltip was animated out
      setTimeout(() => {
        setClickedButton(false);
      }, 300);
    }, 2000);
  }, [wallet]);
  if (!wallet) return null;
  return (
    <Tooltip.Provider>
      <Tooltip.Root open={showTooltip}>
        <Tooltip.Trigger asChild>
          <button
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseOut={() => {
              if (clickedButton) return;
              setShowTooltip(false);
            }}
            onBlur={() => {
              if (clickedButton) return;
              setShowTooltip(false);
            }}
            aria-label={`${wallet.type} wallet ending with ${wallet.address.slice(-4)}, click to copy`}
            className={addressClass}
            onClick={onCopyAddress}
          >
            {shortenAddress(wallet.address, isEthereum ? 6 : 4, 4)}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content>{clickedButton ? 'Copied' : 'Copy Address'} </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function shortenAddress(address: string, startLength = 5, endLength = 5) {
  return `${address.slice(0, startLength)}…${address.slice(-endLength)}`;
}

function DropdownMenuItem({
  wallet,
  onDisconnect,
  balance,
  chainId,
  onNext,
  onPrevious,
  selected,
}: {
  wallet: ConnectedWallet | ConnectedSolanaWallet;
  onDisconnect: (wallet: ConnectedWallet | ConnectedSolanaWallet) => void;
  balance: string | undefined;
  chainId: number;
  onNext?: () => void;
  onPrevious?: () => void;
  selected?: boolean;
}) {
  const { data: priceData, isLoading } = useUSDPrices([
    { address: WRAPPED_NATIVE_ADDRESSES[chainId], networkId: chainId },
  ]);
  const disconnectRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (selected) {
      disconnectRef.current?.focus();
    }
  }, [selected, disconnectRef]);

  return (
    <DropdownMenu.Item
      key={wallet.meta.id}
      className={itemClass}
      onKeyDown={(event) => {
        if (event.key === 'ArrowDown') {
          onNext?.();
        } else if (event.key === 'ArrowUp') {
          onPrevious?.();
        } else if (event.key === 'Enter') {
          onDisconnect(wallet);
        }
      }}
      onSelect={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {wallet && (
        <Web3WalletIcon className={walletAndChainOverrideClass} size={32} wallet={wallet} />
      )}
      <div className={walletInformationClass}>
        {wallet && <ShortenedAddress isEthereum={chainId !== solana.id} wallet={wallet} />}
        <div className={balanceContainerClass}>
          <span className={balanceEmphasisedTextClass}>
            {isLoading ? (
              <ContentLoader
                foregroundColor={tokens.tokenColorGray400}
                backgroundColor={tokens.tokenColorGray200}
                width="80px"
                height="16px"
                uniqueKey={`${wallet.meta.id}-balance-loader`}
              >
                <rect x="0" y="0" width="100%" height="16" rx="4" />
              </ContentLoader>
            ) : (
              balance &&
              tokenFormatter.format(balance, {
                precision: 7,
                symbol: NATIVE_TOKEN_SYMBOL_PER_CHAIN[chainId],
              })
            )}
          </span>
          <span className={balanceMutedTextClass}>{!isLoading && !balance ? null : ' · '}</span>
          <span className={balanceMutedTextClass}>
            {balance && priceData?.[0] ? (
              usdFormatter.format(priceData[0].priceUsd * parseFloat(balance))
            ) : (
              <ContentLoader
                foregroundColor={tokens.tokenColorGray400}
                backgroundColor={tokens.tokenColorGray200}
                width="60px"
                height="16px"
                uniqueKey={`${wallet.meta.id}-usd-loader`}
              >
                <rect x="0" y="0" width="100%" height="16" rx="4" />
              </ContentLoader>
            )}
          </span>
        </div>
      </div>
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <button
              tabIndex={selected === undefined ? undefined : selected ? 0 : -1}
              ref={disconnectRef}
              className={disconnectButtonClass}
              aria-label={`Disconnect ${wallet.type} wallet`}
              onClick={() => onDisconnect(wallet)}
            >
              <DisconnectIcon />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content>Disconnect</Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </DropdownMenu.Item>
  );
}

function DropdownTrigger({ onConnect }: { onConnect: () => void }) {
  const { solanaWallet, ethereumWallet } = useMatchaWallets();
  const { settings: accountSettings } = useAccountStore(accountSettingsSelectors);
  const { data: ensName } = useEnsName({ address: ethereumWallet?.address as EthereumAddress });

  const activeWallets = useMemo(
    () => [ethereumWallet, solanaWallet].filter((val) => val !== undefined),
    [ethereumWallet, solanaWallet],
  );

  if (!solanaWallet && !ethereumWallet) {
    return (
      <button onClick={onConnect} className={btnStyles.PRIMARY_MD}>
        Connect
      </button>
    );
  }

  return (
    <DropdownMenu.Trigger className={accountButtonClass}>
      <span className={accountButtonContentClass}>
        {activeWallets.map((wallet) => (
          <Web3WalletIcon
            size={24}
            className={walletIconOverride}
            wallet={wallet}
            key={wallet.meta.id}
          />
        ))}
        <span className={accountButtonNameClass}>
          {activeWallets.length > 1
            ? 'Wallets'
            : getWalletName(activeWallets[0], accountSettings, ensName)}
        </span>
        <ChevronDownIcon className={chevronDownClass} />
      </span>
    </DropdownMenu.Trigger>
  );
}

function getWalletName(
  activeWallet: ConnectedWallet | ConnectedSolanaWallet,
  accountSettings: AccountSettingsByAddress,
  ensName: GetEnsNameReturnType | undefined,
) {
  if (activeWallet.type === 'ethereum') {
    const address = activeWallet.address as EthereumAddress;
    const storedName = accountSettings[address]?.name;
    if (storedName) {
      return truncate(storedName, 15, true);
    }
    if (ensName) {
      return ensName;
    }
    return formatShortAddress(address);
  }

  if (activeWallet.type === 'solana') {
    const address = activeWallet.address as SolanaAddress;
    return formatShortAddress(address);
  }
}
