import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useComboBox } from '@react-aria/combobox';
import { useComboBoxState } from '@react-stately/combobox';
import { ComboBoxProps } from '@react-types/combobox';
import { forwardRef, useRef } from 'react';
import type { PropsWithChildren, RefObject } from 'react';
import { Chain } from 'viem';

import ListBox from '@/components/ListBox';
import { headerContainerClass } from '@/components/ListBox/ListBoxSection/index.css';

import { useTokenSectionStyles } from '@/hooks/useTokenSectionStyles';

import { containerStyles } from '@/styles/combobox.css';
import { fontStyles } from '@/styles/typography.css';

import { LoadingSpinner } from '@/ui/Icons/Animated/LoadingSpinner';
import { Popover } from '@/ui/Popover';

import { TokenResult } from '@/utils/0x/token-registry.types';

import {
  loaderIconClass,
  noResultsContainerClass,
  noResultsDescriptionClass,
} from '../SearchComboBox/index.css';
import SearchChainFilters from '../SearchComboBox/SearchChainFilters';
import TokenSuggestions from '../SearchComboBox/TokenSuggestions';
import { inputClass, listClass, popoverClass, dividerLineClass } from './index.css';

type CustomComboBoxProps = ComboBoxProps<{}> & {
  chainId: number | undefined;
  className?: string | undefined;
  disabled?: boolean;
  isLoading?: boolean;
  isSearching?: boolean;
  noResults?: boolean;
  allowsEmptyCollection?: boolean;
  error?: boolean | undefined;
  onChangeChainFilter: (chainId: number | undefined) => void;
  suggestedTokensChainId: number;
  showSuggestedTokensNetworkName: boolean;
  onSelectTokenResult: (token: TokenResult) => void;
  supportedChains?: Chain[];
};

const LoadingCell = ({ children }: PropsWithChildren) => (
  <div className={listClass}>
    <div className={headerContainerClass}>
      <LoadingSpinner className={loaderIconClass} />
      <span className={fontStyles.BODY_SM}>{children}</span>
    </div>
  </div>
);

/**
 * A combo box where the listbox is always visible, has a explicit height, and has overflow scroll.
 * Uses base styling from `src/components/combobox`
 */
export const TokenSelectModalComboBox = forwardRef<HTMLInputElement, CustomComboBoxProps>(
  (
    {
      chainId,
      supportedChains,
      error,
      isLoading,
      isSearching,
      noResults,
      onChangeChainFilter,
      suggestedTokensChainId,
      showSuggestedTokensNetworkName,
      onSelectTokenResult,
      ...props
    },
    ref,
  ) => {
    // Create state based on the incoming props and the filter function
    const state = useComboBoxState({
      ...props,
      defaultFilter: undefined,
    });

    const internalInputRef = useRef<HTMLInputElement>(null);
    const listBoxRef = useRef<HTMLUListElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);
    const inputRef = ref ? (ref as RefObject<HTMLInputElement>) : internalInputRef;

    // Get props for child elements from useComboBox
    const { inputProps, listBoxProps } = useComboBox(
      {
        ...props,
        inputRef,
        listBoxRef,
        popoverRef,
        menuTrigger: 'manual',
      },
      state,
    );

    const tokenSectionContainerRef = useTokenSectionStyles();

    return (
      <div className={containerStyles.PRIMARY} onFocus={() => state.setOpen(true)}>
        <VisuallyHidden.Root>
          <label htmlFor={inputRef?.current?.id}>{props.label}</label>
        </VisuallyHidden.Root>
        <input
          className={inputClass}
          {...inputProps}
          ref={inputRef}
          id={inputRef.current?.id}
          placeholder={props.placeholder}
        />
        <Popover
          className={popoverClass}
          popoverRef={popoverRef}
          isOpen={state.isOpen}
          onClose={state.close}
        >
          <SearchChainFilters
            chains={supportedChains}
            defaultValue={chainId}
            onChange={(chainId: number | undefined) => {
              onChangeChainFilter(chainId);
              (inputRef as RefObject<HTMLInputElement>).current?.focus();
            }}
            onDropdownOpenChange={() => {
              (inputRef as RefObject<HTMLInputElement>).current?.focus();
            }}
          />

          <div className={dividerLineClass}>&nbsp;</div>

          {noResults ? (
            <div className={noResultsContainerClass}>
              <div className={fontStyles.LABEL_LG}>No Search Results</div>
              <p className={noResultsDescriptionClass}>
                Try pasting the exact contract address
                <br />
                to find a specific token
              </p>
            </div>
          ) : isSearching ? (
            <LoadingCell>Searching...</LoadingCell>
          ) : error ? (
            <div className={noResultsContainerClass}>
              <div className={fontStyles.LABEL_LG}>Something went wrong</div>
              <p className={noResultsDescriptionClass}>
                Sorry, something went wrong.
                <br />
                Please try again or contact support.
              </p>
            </div>
          ) : (
            <div ref={tokenSectionContainerRef}>
              <ListBox
                {...listBoxProps}
                className={listClass}
                listBoxRef={listBoxRef}
                state={state}
                header={
                  <TokenSuggestions
                    chainId={suggestedTokensChainId}
                    showNetworkName={showSuggestedTokensNetworkName}
                    onClick={onSelectTokenResult}
                  />
                }
              />
            </div>
          )}
        </Popover>
      </div>
    );
  },
);

TokenSelectModalComboBox.displayName = 'TokenSelectModalComboBox';
