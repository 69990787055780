import Decimal from 'decimal.js-light';

import * as errors from './errors';
import Formatter from './formatter';
import type { Amount, Boundaries } from './types';

type FiatFormatterOptions = {
  /** Options for formatting amounts with cents */
  withCents: Intl.NumberFormatOptions;
  /** Options for formatting amounts with cents to 4 sig figs */
  withCentsFourSigFigs: Intl.NumberFormatOptions;
  /** Options for formatting amounts with cents to 3 sig figs */
  withCentsThreeSigFigs: Intl.NumberFormatOptions;
  /** Options for formatting amounts without cents */
  withoutCents: Intl.NumberFormatOptions;
};

/**
 * Displays human readable amounts of a given fiat value.
 *
 * @param currency - The currency code to use for formatting.
 *
 * @example
 * ```ts
 * const formatter = new FiatFormatter('USD');
 * formatter.format(1000000.50); // dollars
 * // outputs: "$1,000,000.50"
 * ```
 */
class FiatFormatter extends Formatter {
  private currencyCode: string;
  private currencySymbol: string;
  private largeNumberFormatter: Intl.NumberFormat;
  private options: FiatFormatterOptions;
  protected boundaries: Boundaries;

  constructor(currency: string) {
    super();

    // set boundaries
    this.boundaries = {
      xs: new Decimal(1e-6),
      sm: new Decimal(1e-2),
      md: new Decimal(1e-1),
      lg: new Decimal(1),
      xl: new Decimal(1e4),
      xxl: new Decimal(1e6),
      xxxl: new Decimal(Number.MAX_SAFE_INTEGER),
    };

    // set currency details
    this.currencyCode = currency;
    this.currencySymbol = this.getCurrencySymbol();

    // create number formatter for large numbers
    this.largeNumberFormatter = new Intl.NumberFormat(this.locale, {
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      notation: 'compact',
      style: 'currency',
    });

    // options to use for fixed locale string formatting
    this.options = {
      withCents: {
        currency: this.currencyCode,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: 'currency',
      },
      withCentsThreeSigFigs: {
        currency: this.currencyCode,
        maximumFractionDigits: 3,
        minimumFractionDigits: 3,
        style: 'currency',
      },
      withCentsFourSigFigs: {
        currency: this.currencyCode,
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
        style: 'currency',
      },
      withoutCents: {
        currency: this.currencyCode,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency',
      },
    };
  }

  /**
   * Formats a fiat amount value to be human readable.
   *
   * @param amount - The amount to format in fiat units (dollars, euros, etc...)
   * @param showExtraSmallAmounts - Whether to show amounts less than 0.01.
   */
  public format(amount: Amount, showExtraSmallAmounts?: boolean): string {
    // null checks
    if (
      !this.boundaries.xs ||
      !this.boundaries.sm ||
      !this.boundaries.md ||
      !this.boundaries.lg ||
      !this.boundaries.xl ||
      !this.boundaries.xxl ||
      !this.boundaries.xxxl
    ) {
      throw errors.missingBoundaries;
    }

    // variables
    let humanReadable = '';
    const value = new Decimal(amount);

    // formatting logic
    if (value.eq(0)) {
      // zero
      humanReadable = this.toFixedLocaleString(value, 2, this.options.withCents);
    } else if (value.lt(this.boundaries.xs)) {
      // below 0.000001
      humanReadable = showExtraSmallAmounts
        ? this.currencySymbol + this.toExponential(value)
        : `< ${this.currencySymbol}0.01`;
    } else if (value.gte(this.boundaries.xs) && value.lt(this.boundaries.sm)) {
      // between 0.000001 and 0.01
      humanReadable = showExtraSmallAmounts
        ? this.currencySymbol + this.toSigFigs(value, 3)
        : `< ${this.currencySymbol}0.01`;
    } else if (value.gte(this.boundaries.sm) && value.lt(this.boundaries.md)) {
      // between 0.01 and 0.10
      humanReadable = this.toFixedLocaleString(value, 4, this.options.withCentsFourSigFigs);
    } else if (value.gte(this.boundaries.md) && value.lt(this.boundaries.lg)) {
      // between 0.10 and 1.00
      humanReadable = this.toFixedLocaleString(value, 3, this.options.withCentsThreeSigFigs);
    } else if (value.gte(this.boundaries.lg) && value.lt(this.boundaries.xl)) {
      // between 1.00 and 10,000
      humanReadable = this.toFixedLocaleString(value, 2, this.options.withCents);
    } else if (value.gte(this.boundaries.xl) && value.lt(this.boundaries.xxl)) {
      // between 10,000 and 1,000,000
      humanReadable = this.toFixedLocaleString(value, 2, this.options.withoutCents);
    } else if (value.gte(this.boundaries.xxl) && value.lte(this.boundaries.xxxl)) {
      // between 1,000,000 and 9,007,199,254,740,991 inclusive
      humanReadable = this.largeNumberFormatter.format(value.toNumber());
    } else if (value.gt(this.boundaries.xxxl)) {
      // extremely large numbers that Javascript cannot represent, so the
      // currency symbol is prepended to the exponential notation
      humanReadable = `${this.currencySymbol}${this.toExponential(value)}`;
    } else {
      // should never happen
      throw errors.amountOutOfRange;
    }

    return humanReadable;
  }

  /**
   * Returns the currency symbol for the given locale and currency.
   */
  private getCurrencySymbol(): string {
    return (0)
      .toLocaleString(this.locale, {
        style: 'currency',
        currency: this.currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  }
}

export default FiatFormatter;
