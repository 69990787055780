import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useMedia } from 'react-use';

import { useKeyPress } from '@/hooks/useKeypress';

import { resetCheckoutSelector, useTradeStore } from '@/store/trade';
import { recentTransactionSelector, useTransactionStore } from '@/store/transaction';

import { TokenResult } from '@/utils/0x/token-registry.types';

import {
  NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID,
  SUPPORTED_CHAINS_BY_CHAIN_ID,
  SUPPORTED_CHAIN_IDS_CROSS_CHAIN,
} from '../../constants/chain';
import { useMatchaWallets } from '../../hooks/useMatchaWallets';
import { btnStyles } from '../../styles/buttons.css';
import { fontStyles } from '../../styles/typography.css';
import { breakpoints } from '../../styles/util';
import {
  SearchIcon,
  ChevronDownIcon,
  TradeHistoryIcon,
  NotificationIndicatorIcon,
} from '../../ui/Icons';
import { EVENT_NAME, logEvent, SEARCH_TYPES } from '../../utils/amplitude';
// utils
import { routes } from '../../utils/routes';
import { getConnectedChain } from '../../utils/wallet';
import TokenSelectModal from '../TransactionModule/TokenSelectModal';
import AdditionalOptionsDropdown from './AdditionalOptionsDropdown';
import AnnouncementBanner from './AnnouncementBanner';
import { FeaturesPageNavigationMenu } from './FeaturesPageNavigationMenu';
import {
  FeatureFlagIndicator,
  HeaderConnectButton,
  HeaderCreateWalletButton,
} from './HeaderButtons';
import {
  searchBarClass,
  connectWalletClass,
  marketingMenuMobileContainerClass,
  marketingPageMobileNavigationDropdownButtonClass,
  chevronIconClass,
  popoverContentClass,
  searchIconButtonClass,
  triggerClass,
  searchIconClass,
  searchHotKeyClass,
  headerContainer,
  mainHeaderRowContainer,
  tradeHistoryLink,
  notificationIndicatorIcon,
  headerTextClass,
} from './index.css';
import { LearnMoreButtonDropdown } from './LearnMoreButtonDropdown';
import MatchaLogoButton from './MatchaLogoButton';
import { NavigationDropdownList } from './NavigationDropdownList';

/**
 * Props for the Header component
 */
interface HeaderProps {
  /** Whether to show or hide the global search bar. */
  hideSearch?: boolean;
  /** Whether to show or hide the connect wallet button. */
  hideConnect?: boolean;
  hideBlogButton?: boolean;
  hideCompanyButton?: boolean;
  hideOpenAppButton?: boolean;
  hideLearnMoreDropdown?: boolean;
  hideCenteredNavigation?: boolean;
  hideMarketingMobileNavigation?: boolean;
  /** Intersection entry that triggers a callback when the element intersects the header. */
  intersection?: IntersectionObserverEntry | null;
  isHomepage?: boolean;
}

/**
 * Landing page hero content.
 * @param props - HeaderProps
 */
const Header = ({
  hideSearch,
  hideConnect,
  intersection,
  hideBlogButton = false,
  hideOpenAppButton = true,
  hideLearnMoreDropdown = true,
  hideCenteredNavigation = true,
  hideMarketingMobileNavigation = true,
  isHomepage = false,
}: HeaderProps) => {
  const router = useRouter();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const hasSlashBeenPressed = useKeyPress('/');
  const isMobile = useMedia(breakpoints.mobile, false);
  const { ethereumWallet, isConnected } = useMatchaWallets();
  const evmChain = getConnectedChain(ethereumWallet);
  const shouldBlurBackground = !intersection?.isIntersecting;
  const isChainIdSupportedForCrossChain =
    evmChain !== undefined && SUPPORTED_CHAIN_IDS_CROSS_CHAIN.indexOf(evmChain.id) !== -1;
  const resetCheckout = useTradeStore(resetCheckoutSelector);

  useEffect(() => {
    if (!hasSlashBeenPressed) return;
    const element = document.activeElement;

    const shouldFocus =
      !(element instanceof HTMLInputElement) &&
      !(element instanceof HTMLTextAreaElement) &&
      !(
        element instanceof HTMLElement &&
        (element.contentEditable === 'true' || element.contentEditable === '')
      );
    if (!shouldFocus) return;

    // set timeout so focus happens after the slash key event has bubbled
    // so '/' isn't typed into the input field.
    setTimeout(() => buttonRef.current?.click(), 0);
  }, [hasSlashBeenPressed]);

  const handleSelectTokenResult = async (token: TokenResult) => {
    await router.push({
      pathname: routes.TOKEN_PROFILE,
      query: {
        chainId: NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[token.chainId],
        contractAddresses: [token.address],
      },
    });
    resetCheckout();
  };

  // Always show the announcement banner on tabled and desktop; while on mobile, only if it's the homepage
  const shouldShowAnnouncementBanner = !isMobile || isHomepage;

  const { recentTransaction } = useTransactionStore(recentTransactionSelector);

  // event tracking
  const searchType = isHomepage ? SEARCH_TYPES.HOME_PAGE : SEARCH_TYPES.TOKEN_PAGE_TOP;
  const tokenSelectEvent = {
    name: EVENT_NAME.SEARCH_BOX_INITIATED,
    properties: {
      search_type: searchType,
    },
  };

  return (
    <header className={headerContainer}>
      {shouldShowAnnouncementBanner && <AnnouncementBanner />}

      <div className={mainHeaderRowContainer} data-should-blur={shouldBlurBackground}>
        <MatchaLogoButton />
        {/** handle tablet/desktop global search bar visibility */}
        {!isMobile && !hideSearch && (
          <div className={searchBarClass}>
            <TokenSelectModal onSelect={handleSelectTokenResult} searchType={searchType}>
              <button
                className={triggerClass}
                ref={buttonRef}
                onClick={() => logEvent(tokenSelectEvent)}
              >
                <span className={headerTextClass}>Search token name, symbol or address...</span>
                <SearchIcon className={searchIconClass} />
                <span className={searchHotKeyClass}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9368 2.70532L7.88991 14.0249H5.96484L9.01172 2.70532H10.9368Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </button>
            </TokenSelectModal>
          </div>
        )}
        {!hideCenteredNavigation ? <FeaturesPageNavigationMenu /> : null}
        <nav className={marketingMenuMobileContainerClass}>
          {!hideOpenAppButton && (
            <Link
              href={{
                pathname: routes.TOKEN_PROFILE,
                query: {
                  chainId:
                    evmChain !== undefined
                      ? NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[evmChain.id]
                      : 'ethereum',
                  contractAddresses: [
                    isChainIdSupportedForCrossChain
                      ? SUPPORTED_CHAINS_BY_CHAIN_ID[
                          evmChain.id
                        ]?.nativeCurrency.symbol.toLowerCase()
                      : 'eth',
                  ],
                },
              }}
            >
              <div className={btnStyles.PRIMARY_MD}>Open App</div>
            </Link>
          )}

          {!hideMarketingMobileNavigation ? (
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className={marketingPageMobileNavigationDropdownButtonClass}>
                <span className={fontStyles.LABEL_MD}>Menu</span>
                <ChevronDownIcon className={chevronIconClass} />
              </DropdownMenu.Trigger>

              <DropdownMenu.Portal>
                <DropdownMenu.Content align="end" className={popoverContentClass}>
                  <NavigationDropdownList isInFeaturesPage />
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          ) : null}
        </nav>
        {!hideConnect && (
          <nav className={connectWalletClass}>
            {!hideLearnMoreDropdown ? (
              <LearnMoreButtonDropdown hideBlogButton={hideBlogButton} />
            ) : (
              <Link
                className={tradeHistoryLink}
                href={{ pathname: routes.TRADE_HISTORY }}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_HISTORY })}
              >
                Trade History
                {recentTransaction && (
                  <NotificationIndicatorIcon className={notificationIndicatorIcon} />
                )}
              </Link>
            )}

            <FeatureFlagIndicator />

            <HeaderConnectButton />

            {!isConnected && <HeaderCreateWalletButton />}

            {/** handle mobile token search visibility */}
            {isMobile && !hideSearch && (
              <TokenSelectModal
                onSelect={handleSelectTokenResult}
                searchType={isHomepage ? SEARCH_TYPES.HOME_PAGE : SEARCH_TYPES.TOKEN_PAGE_TOP}
              >
                <div className={searchIconButtonClass}>
                  <SearchIcon height={16} width={16} />
                  <VisuallyHidden>Search</VisuallyHidden>
                </div>
              </TokenSelectModal>
            )}

            {isConnected && (
              <Link
                className={searchIconButtonClass}
                aria-label="trade history"
                href={{ pathname: routes.TRADE_HISTORY }}
              >
                <div>
                  <TradeHistoryIcon height={16} width={16} />
                </div>
              </Link>
            )}

            <AdditionalOptionsDropdown />
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
