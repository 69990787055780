import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';
import { ToggleGroup, ToggleGroupItem } from '@radix-ui/react-toggle-group';
import Image from 'next/image';
import { Key, useMemo, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useMedia } from 'react-use';
import { Chain } from 'viem';

import {
  NETWORK_NAME_PER_CHAIN_ID,
  SUPPORTED_CHAINS,
  SUPPORTED_CHAINS_V2,
} from '@/constants/chain';

import { SOLANA_PHASE1_COOKIE_KEY, useFeature } from '@/hooks/useFeature';
import { useMeasure } from '@/hooks/useMeasure';

import { avatarStyles } from '@/styles/avatars.css';
import { popoverClass, popoverItemClass } from '@/styles/popover.css';
import { tabsStyles } from '@/styles/tabs.css';
import { breakpoints } from '@/styles/util';

import { CheckmarkIcon, ChevronDownIcon } from '@/ui/Icons';
import { ChainIcons } from '@/ui/Icons/TokenChainIcon';
import ImageWithFallback from '@/ui/Icons/TokenIcon/fallback';
import {
  MatchaTooltipPopover,
  MatchaTooltip,
  MatchaTooltipTrigger,
  MatchaTooltipContent,
} from '@/ui/MatchaTooltip';

import { containerClass, checkedIconClass, dropdownItemLabelClass, tabClass } from './index.css';

const ALL_NETWORK_ITEM_WIDTH = 48; // button + gap
const ITEM_WIDTH = 48; // button + gap

const NO_FILTER = 'all';
const ALL_NETWORKS = {
  title: 'All',
  filter: NO_FILTER,
} as const;

interface Props {
  chains?: Chain[];
  onChange: (chainId: number | undefined) => void;
  onDropdownOpenChange?: (isOpen: boolean) => void;
  defaultValue: number | undefined;
}

const SearchChainFilters = ({ chains, defaultValue, onChange, onDropdownOpenChange }: Props) => {
  const isSolanaPhase1 = useFeature(SOLANA_PHASE1_COOKIE_KEY);
  const supportedChains = isSolanaPhase1 ? SUPPORTED_CHAINS_V2 : SUPPORTED_CHAINS;
  const FILTERS = [
    ALL_NETWORKS,
    ...(chains || supportedChains).map(({ id }) => ({
      title: NETWORK_NAME_PER_CHAIN_ID[id],
      filter: id.toString(),
    })),
  ];

  const [value, setValue] = useState(defaultValue?.toString() || FILTERS[0].filter);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function handleChangeFilter(filter: Key) {
    if (!filter) return;
    setValue(filter.toString());
    onChange(filter !== ALL_NETWORKS.filter ? Number(filter) : undefined);
  }

  const containerRef = useRef<HTMLDivElement>(null);

  const {
    nodeRef,
    bounds: { width: containerWidth },
  } = useMeasure<HTMLDivElement>();

  const mergedRef = useMemo(() => mergeRefs([containerRef, nodeRef]), [containerRef, nodeRef]);
  const isMobile = useMedia(breakpoints.mobile, false);

  const maxItemsInContainer = Math.floor((containerWidth - ALL_NETWORK_ITEM_WIDTH) / ITEM_WIDTH);
  const filtersLength = FILTERS.length;
  const shouldShowMoreButton = filtersLength > maxItemsInContainer;
  const maxToggleItems = isMobile
    ? filtersLength
    : shouldShowMoreButton
      ? maxItemsInContainer
      : maxItemsInContainer;
  const toggleItems = containerWidth ? FILTERS.slice(0, maxToggleItems) : [];
  const dropdownItems = containerWidth ? FILTERS.slice(maxToggleItems) : [];
  const selectedDropdownItem =
    dropdownItems.length > 0 ? dropdownItems.find(({ filter }) => filter === value) : undefined;
  const selectedChainImgSrc =
    !!selectedDropdownItem && selectedDropdownItem.filter !== ALL_NETWORKS.filter
      ? ChainIcons[Number(selectedDropdownItem.filter)]
      : undefined;
  return (
    <div className={containerClass} ref={mergedRef}>
      {toggleItems.length > 0 && (
        <ToggleGroup
          aria-label="Filter results by chain"
          className={tabsStyles.SECONDARY}
          onValueChange={handleChangeFilter}
          type="single"
          value={value}
        >
          {toggleItems.map(({ title, filter }) => {
            if (filter === NO_FILTER)
              return (
                <ToggleGroupItem className={tabClass} key="all" value={ALL_NETWORKS.filter}>
                  {ALL_NETWORKS.title}
                </ToggleGroupItem>
              );

            const ChainIcon = ChainIcons[Number(filter)];
            return (
              <MatchaTooltip key={title}>
                <MatchaTooltipTrigger>
                  <div>
                    <ToggleGroupItem className={tabClass} value={filter}>
                      <ImageWithFallback
                        height={16}
                        width={16}
                        src={ChainIcon}
                        alt={title}
                        className={avatarStyles.TINY}
                      />
                    </ToggleGroupItem>
                  </div>
                </MatchaTooltipTrigger>
                <MatchaTooltipContent>{title}</MatchaTooltipContent>
                <MatchaTooltipPopover style={{ padding: '.5rem' }} />
              </MatchaTooltip>
            );
          })}
        </ToggleGroup>
      )}

      {dropdownItems.length > 0 && (
        <DropdownMenu
          /* Keep modal false or the page may become unresponsive
           * When dismissing parent component while Dropdown is open,
           * the Dropdown Menu's overlay sticks and covers the page. */
          modal={false}
          onOpenChange={(isOpen: boolean) => {
            setIsDropdownOpen(isOpen);
            onDropdownOpenChange?.(isOpen);
          }}
          open={isDropdownOpen}
        >
          <MatchaTooltip>
            <MatchaTooltipTrigger>
              <div>
                <DropdownMenuTrigger className={tabClass} data-selected={!!selectedDropdownItem}>
                  {!!selectedDropdownItem && selectedChainImgSrc ? (
                    <Image
                      className={dropdownItemLabelClass}
                      height={16}
                      width={16}
                      src={selectedChainImgSrc ?? ''}
                      alt={selectedDropdownItem.title}
                    />
                  ) : (
                    <span className={dropdownItemLabelClass}>More</span>
                  )}
                  <ChevronDownIcon />
                </DropdownMenuTrigger>
              </div>
            </MatchaTooltipTrigger>
            <MatchaTooltipContent>
              {selectedDropdownItem?.title || `+${dropdownItems.length} Networks`}
            </MatchaTooltipContent>
            <MatchaTooltipPopover />
          </MatchaTooltip>
          <DropdownMenuPortal container={containerRef.current}>
            <DropdownMenuContent className={popoverClass}>
              {dropdownItems.map(({ title, filter }) => {
                const selected = value === filter;
                return (
                  <DropdownMenuItem
                    className={popoverItemClass}
                    key={title}
                    onSelect={() => handleChangeFilter(filter)}
                  >
                    <Image
                      className={dropdownItemLabelClass}
                      height={16}
                      width={16}
                      src={ChainIcons[Number(filter)]}
                      alt={title}
                    />
                    <span>{title}</span>
                    {selected && <CheckmarkIcon className={checkedIconClass} />}
                  </DropdownMenuItem>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenuPortal>
        </DropdownMenu>
      )}
    </div>
  );
};

export default SearchChainFilters;
