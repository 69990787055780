import { useCallback } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { ClientNetworkError } from '@/lib/errors/client';

import { REFRESH_INTERVAL } from '../constants/rpc';
import { GetPriceInput, DefinedApiUsdPriceResponse, Price } from '../utils/defined.types';
import { routes } from '../utils/routes';
import { getFullURL, handleResponse } from '../utils/swr';

const INPUTS_LIMIT = 25;

/**
 * Fetches USD Prices for specified token(s) from the getTokenPrices endpoint from Defined.fi through /api.
 *
 * @param inputs - Array of type GetPriceInput. When data resolves, it should match GetPriceInput and Price via index.
 * @param watch - Gets the updated price on each new block.
 * @param onSuccess - Callback function that runs when data is successfully fetched from network.
 * @returns DefinedApiUsdPriceResponse
 */
export const useUSDPrices = (
  inputs: GetPriceInput[] | undefined,
  watch = true,
  onSuccess?: (data: DefinedApiUsdPriceResponse) => void,
) => {
  const usdPricesFetcher = useCallback(async () => {
    if (!inputs?.length) return Promise.resolve([]);

    const clonedInputs = [...inputs];
    const inputsGroups = [];
    // To stay under the max request input limit for the Defined API,
    // group the addresses in batches of INPUTS_LIMIT.
    while (clonedInputs.length > 0) {
      inputsGroups.push(clonedInputs.splice(0, INPUTS_LIMIT));
    }

    try {
      const responses = await Promise.all(
        inputsGroups.map((inputsBatch) =>
          fetch(
            getFullURL(
              `${routes.api.USD_PRICES}?inputs=${encodeURIComponent(JSON.stringify(inputsBatch))}`,
            ),
          ).then(handleResponse<(Price | null)[]>),
        ),
      );

      const results = ([] as (Price | null)[]).concat(...responses);

      return results;
    } catch (err) {
      if (err instanceof Error) {
        throw err;
      }
    }

    throw new ClientNetworkError('Token results failed to fetch.');
  }, [inputs]);

  // build options
  let options: SWRConfiguration = {};
  if (watch) options.refreshInterval = REFRESH_INTERVAL;
  if (onSuccess) options.onSuccess = onSuccess;

  return useSWR<DefinedApiUsdPriceResponse>(
    !!inputs?.length ? [`usdPrices-${JSON.stringify(inputs)}`, inputs] : null,
    usdPricesFetcher,
    options,
  );
};
