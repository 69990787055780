import { CSSProperties } from 'react';

import { IconStyle } from '../ui/Icons';
import { hexToHSL, hslToRGBA } from './colors';

type TokenProminentColorButtonStyles = {
  button: CSSProperties;
  buttonHover: CSSProperties;
  label: CSSProperties;
  accessoryIcon: IconStyle;
};
export interface ThemeAwareTokenStyles {
  light: TokenProminentColorButtonStyles | undefined;
  dark: TokenProminentColorButtonStyles | undefined;
}

function isLowContrastHSL({ l }: { l: number }): boolean {
  return l > 85 || l < 15;
}

// The following functions act as hue adjustment functions for light and dark mode
// the values have been somewhat reversed engineered from figma files, but seem to
// be pretty accurate

function getDarkModeHue(baseHue: number): number {
  if (baseHue >= 100 && baseHue <= 180) {
    return baseHue + 19;
  } else if (baseHue >= 240 && baseHue <= 290) {
    return baseHue - 3;
  } else if (baseHue >= 40 && baseHue <= 60) {
    return baseHue - 3;
  } else if (baseHue >= 200 && baseHue <= 220) {
    return baseHue + 4;
  }
  return baseHue; // default no shift
}

function getLightModeHue(baseHue: number): number {
  if (baseHue >= 100 && baseHue <= 180) {
    return baseHue - 3;
  } else if (baseHue >= 240 && baseHue <= 290) {
    return baseHue - 3;
  } else if (baseHue >= 40 && baseHue <= 60) {
    return baseHue - 3;
  } else if (baseHue >= 200 && baseHue <= 220) {
    return baseHue;
  }
  return baseHue; // default no shift
}

/**
 * This function converts a base prominent color into shades for light and dark mode.
 * This is done by converting the color into HSL space and then performing hue rotation
 * and lightness shifts.
 *
 */
export function tokenProminentColorButtonStylesByThemeHSL(color: string): ThemeAwareTokenStyles {
  const { h, s, l } = hexToHSL(color);

  // If extremely light or dark, bail out
  if (isLowContrastHSL({ l })) {
    return { light: undefined, dark: undefined };
  }

  const lightmodeH = getLightModeHue(h);
  // We'll clamp saturation between ~0.30 and 0.45 so it stays pastel
  const lightmodeS = Math.max(30, Math.min(45, s));
  const lightmodeL = 95; // near-white background
  const lightBg = hslToRGBA(lightmodeH, lightmodeS, lightmodeL);
  const lightBorder = hslToRGBA(lightmodeH, lightmodeS, lightmodeL, 0.1);
  // Hover can be slightly darker
  const lightBgHover = hslToRGBA(lightmodeH, lightmodeS, lightmodeL - 3);

  const labelLightness = Math.max(20, l - 20);
  const labelColor = hslToRGBA(lightmodeH, lightmodeS, labelLightness, 1);

  const lightTheme: TokenProminentColorButtonStyles = {
    button: { background: lightBg, borderColor: lightBorder },
    buttonHover: { background: lightBgHover },
    label: { color: labelColor },
    accessoryIcon: { fill: labelColor },
  };

  const darkmodeH = getDarkModeHue(h);
  // clamp saturation to  0.15–0.35
  const darkmodeS = Math.max(15, Math.min(35, s));
  const darkmodeL = 23; // near-black background
  const darkBg = hslToRGBA(darkmodeH, darkmodeS, darkmodeL);
  const darkBorder = hslToRGBA(darkmodeH, darkmodeS, darkmodeL, 0.2);
  // Hover a bit lighter
  const darkBgHover = hslToRGBA(darkmodeH, darkmodeS, darkmodeL + 5);

  const darkLabelLightness = Math.min(85, l + 40);
  const darkLabelColor = hslToRGBA(darkmodeH, darkmodeS, darkLabelLightness, 1);

  const darkTheme: TokenProminentColorButtonStyles = {
    button: { background: darkBg, borderColor: darkBorder },
    buttonHover: { background: darkBgHover },
    label: { color: darkLabelColor },
    accessoryIcon: { fill: darkLabelColor },
  };

  return {
    light: lightTheme,
    dark: darkTheme,
  };
}
