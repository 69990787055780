import { getImageProps } from 'next/image';
import { useEffect, useId, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { renderToStaticMarkup } from 'react-dom/server';

import { CHAIN_IDS, NETWORK_NAME_PER_CHAIN_ID } from '@/constants/chain';

import { classNames } from '@/utils/classnames';
import { toBase64 } from '@/utils/string';

import * as arb from '../../../../public/images/chains/arbitrum.svg';
import * as avax from '../../../../public/images/chains/avax.svg';
import * as base from '../../../../public/images/chains/base.svg';
import * as blast from '../../../../public/images/chains/blast.svg';
import * as bsc from '../../../../public/images/chains/bsc.svg';
import * as eth from '../../../../public/images/chains/eth.svg';
import * as linea from '../../../../public/images/chains/linea.svg';
import * as mantle from '../../../../public/images/chains/mantle.svg';
import * as matic from '../../../../public/images/chains/matic.svg';
import * as mode from '../../../../public/images/chains/mode.svg';
import * as none from '../../../../public/images/chains/none.svg';
import * as optimism from '../../../../public/images/chains/optimism.svg';
import * as scroll from '../../../../public/images/chains/scroll.svg';
import * as solana from '../../../../public/images/chains/solana.svg';
import * as unichain from '../../../../public/images/chains/unichain.svg';
import { avatarCircleShadowClass, dropShadowClass, iconClass, wrapperClass } from './index.css';

// Note: When updating the ChainIcons, make sure to update the ChainIconsPng as well.
export const ChainIcons: { [chainId: number]: string } = {
  [CHAIN_IDS.MAINNET]: eth.default.src,
  [CHAIN_IDS.MATIC]: matic.default.src,
  [CHAIN_IDS.BASE]: base.default.src,
  [CHAIN_IDS.BSC]: bsc.default.src,
  [CHAIN_IDS.AVALANCHE]: avax.default.src,
  [CHAIN_IDS.ARBITRUM]: arb.default.src,
  [CHAIN_IDS.OPTIMISM]: optimism.default.src,
  [CHAIN_IDS.SCROLL]: scroll.default.src,
  [CHAIN_IDS.BLAST]: blast.default.src,
  [CHAIN_IDS.LINEA]: linea.default.src,
  [CHAIN_IDS.MANTLE]: mantle.default.src,
  [CHAIN_IDS.MODE]: mode.default.src,
  [CHAIN_IDS.SOLANA]: solana.default.src,
  [CHAIN_IDS.UNICHAIN]: unichain.default.src,
};

export const ChainIconsPng: { [chainId: number]: string } = {
  [CHAIN_IDS.MAINNET]: '/images/chains/eth.png',
  [CHAIN_IDS.MATIC]: '/images/chains/matic.png',
  [CHAIN_IDS.BASE]: '/images/chains/base.png',
  [CHAIN_IDS.BSC]: '/images/chains/bsc.png',
  [CHAIN_IDS.AVALANCHE]: '/images/chains/avax.png',
  [CHAIN_IDS.ARBITRUM]: '/images/chains/arbitrum.png',
  [CHAIN_IDS.OPTIMISM]: '/images/chains/optimism.png',
  [CHAIN_IDS.SCROLL]: '/images/chains/scroll.png',
  [CHAIN_IDS.BLAST]: '/images/chains/blast.png',
  [CHAIN_IDS.LINEA]: '/images/chains/linea.png',
  [CHAIN_IDS.MANTLE]: '/images/chains/mantle.png',
  [CHAIN_IDS.MODE]: '/images/chains/mode.png',
  [CHAIN_IDS.SOLANA]: '/images/chains/solana.png',
  [CHAIN_IDS.UNICHAIN]: '/images/chains/unichain.png',
};

interface TokenChainImageProps {
  chainId?: number;
  logoUrl?: string;
  className?: string;
  isLargeVariant?: boolean;
  size?: number;
}

const loadingPlaceholder = renderToStaticMarkup(
  <ContentLoader viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" />
  </ContentLoader>,
);

const dataUrl: `data:image/${string}` = `data:image/svg+xml;base64,${toBase64(loadingPlaceholder)}`;

export function ImageChainOverlayIcon({
  chainId,
  logoUrl,
  className,
  isLargeVariant,
  size,
}: TokenChainImageProps) {
  const [showFallback, setShowFallback] = useState(!logoUrl);

  // gets a SSR-safe ID
  const id = useId();

  useEffect(() => {
    setShowFallback(!logoUrl);
  }, [logoUrl]);

  useEffect(() => {
    if (size !== undefined && isLargeVariant !== undefined) {
      console.warn(
        'Both size and isLargeVariant are set. This is likely a bug. `size` takes precedence.',
      );
    }
  }, [size, isLargeVariant]);

  const sizeToShow = useMemo(() => {
    if (size !== undefined) return size;
    if (isLargeVariant !== undefined) {
      return isLargeVariant ? 40 : 32;
    }
    return 32;
  }, [size, isLargeVariant]);

  const chainSize = useMemo(() => {
    return sizeToShow * (14 / 36);
  }, [sizeToShow]);

  const chainSrcName = chainId ? ChainIcons[chainId] : none.default.src;

  const [finalLogoUrl, isTokenRegistryLogo] = useMemo(() => {
    if (!logoUrl) return ['', false];

    let replaced = logoUrl;
    if (logoUrl.includes('ipfs')) {
      replaced = replaced.replace('https://ipfs.io/ipfs/', 'https://ipfs.euc.li/ipfs/');
    }
    return [replaced, replaced.includes('token-registry')];
  }, [logoUrl]);

  const tokenSrc = showFallback ? none.default.src : finalLogoUrl;

  const chainAltText = chainId
    ? NETWORK_NAME_PER_CHAIN_ID[chainId] || 'Unknown Chain Logo'
    : 'Unknown Chain Logo';

  const tokenPropsCommon = {
    className: iconClass,
    src: tokenSrc,
    alt: 'Token logo',
    width: sizeToShow,
    height: sizeToShow,
    onError: () => setShowFallback(true),
  };
  const tokenProps = isTokenRegistryLogo
    ? /**
       * Currently, we use dynamic image URLs for Solana token logos, so we opt
       * not to optimize them with next/image. If we ever decide to scrape
       * these images into our own services, we can then optimize them with
       * next/image.
       */
      getImageProps({
        ...tokenPropsCommon,
        placeholder: sizeToShow > 40 ? 'blur' : 'empty',
        blurDataURL: sizeToShow > 40 ? dataUrl : undefined,
      }).props
    : tokenPropsCommon;

  const chainCommonProps = {
    src: chainSrcName,
    alt: chainAltText,
    width: chainSize,
    height: chainSize,
  };
  const chainProps = isTokenRegistryLogo
    ? getImageProps({
        ...chainCommonProps,
        placeholder: chainSize > 40 ? 'blur' : 'empty',
        blurDataURL: chainSize > 40 ? dataUrl : undefined,
      }).props
    : /**
       * Currently, we use dynamic image URLs for Solana token logos, so we opt
       * not to optimize them with next/image. If we ever decide to scrape
       * these images into our own services, we can then optimize them with
       * next/image.
       */
      chainCommonProps;

  const clipPathCircleWalletIcon = `clip-path-1-${id}`;
  const clipPathCircleChainIcon = `clip-path-2-${id}`;
  const shadowId = `shadow-${id}`;

  return (
    <div className={classNames(wrapperClass, className)}>
      <svg height={sizeToShow} viewBox="0 0 40 36" shapeRendering="crispEdges" overflow="visible">
        <defs>
          <filter id={shadowId} x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow
              dx="0"
              dy="1"
              stdDeviation="1"
              floodColor="rgb(0, 0, 0)"
              floodOpacity="0.20"
            />
          </filter>
          <clipPath id={clipPathCircleWalletIcon}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M35.5988 21.7972C35.8616 20.573 36 19.3027 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C20.5386 36 22.9544 35.4745 25.1446 34.5263C24.1107 33.1185 23.5 31.3806 23.5 29.5C23.5 24.8056 27.3056 21 32 21C33.2863 21 34.5059 21.2857 35.5988 21.7972Z"
            />
          </clipPath>
          <clipPath id={clipPathCircleChainIcon}>
            <circle cx={32} cy={29} r={7} />
          </clipPath>
        </defs>

        <g className={dropShadowClass}>
          {/* Wallet icon */}
          <image
            height={36}
            width={36}
            x={0}
            y={0}
            href={tokenProps.src}
            clipPath={`url(#${clipPathCircleWalletIcon})`}
            preserveAspectRatio="xMidYMid slice"
            filter={`url(#${shadowId})`}
          />
          {/* Wallet icon shadow/border */}
          <circle
            cx={18}
            cy={18}
            r={17}
            clipPath={`url(#${clipPathCircleWalletIcon})`}
            className={avatarCircleShadowClass}
          />
        </g>

        <g className={dropShadowClass}>
          {/* Chain icon */}
          <image
            height={14}
            width={14}
            y={22.5}
            x={24.5}
            href={chainProps.src}
            clipPath={`url(#${clipPathCircleChainIcon})`}
            preserveAspectRatio="xMidYMid slice"
          />
          {/* Chain icon shadow/border */}
          <circle cx={32} cy={29.5} r={6} className={avatarCircleShadowClass} />
        </g>
      </svg>
    </div>
  );
}
