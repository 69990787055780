import { useState, useEffect, useCallback } from 'react';

/**
 * Used to track dimensions of an HTML element using the ResizeObserver API.
 *
 * Example Use case:
 * ```tsx
 *   const {bounds: elementBounds, nodeRef: componentRef} = useMeasure()
 *   <div ref={componentRef}>
 *      <svg width={elementBounds.width} height={elementBounds.height}> ...
 *    <div/>
 * ```
 */
export function useMeasure<T extends Element>() {
  const [elem, setElem] = useState<T | null>(null);
  const [bounds, setBounds] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const ref = useCallback((e: T) => {
    setElem(e);
    if (e) {
      setBounds(e.getBoundingClientRect());
    }
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(([entry]: ResizeObserverEntry[]) =>
      setBounds(entry.contentRect),
    );

    if (elem) {
      observer.observe(elem);
    }

    return () => {
      observer.disconnect();
    };
  }, [elem]);

  return {
    bounds,
    nodeRef: ref,
  };
}
