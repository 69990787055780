import * as ToastPrimitive from '@radix-ui/react-toast';
import { useRef } from 'react';

import { AlertIcon, ErrorIcon, SuccessIcon, XIcon } from '../../ui/Icons';
import { toastContainer, toastFont, toastCloseIcon, toastIconClass } from './index.css';

interface Props {
  description?: string;
  duration?: number;
  openState: boolean;
  type?: 'success' | 'alert' | 'error' | 'neutral';
  onOpenChange: (state: boolean) => void;
  setToastMessage?: (message: string) => void;
}

const Toast = ({
  openState = false,
  description,
  duration = 3000,
  onOpenChange,
  type = 'neutral',
  setToastMessage = undefined,
}: Props) => {
  const timerRef = useRef(0);
  const activateToast = (duration: number) => {
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      if (onOpenChange) {
        // reset the toast message for reusability
        if (setToastMessage) setToastMessage('');
        onOpenChange(false);
      }
    }, duration);
  };
  return (
    <ToastPrimitive.Root
      open={openState}
      onOpenChange={() => activateToast(duration)}
      className={toastContainer}
      duration={3000}
      type="foreground"
    >
      <span aria-hidden className={toastIconClass}>
        {type === 'alert' ? (
          <AlertIcon toastIcon />
        ) : type === 'success' ? (
          <SuccessIcon toastIcon />
        ) : (
          <ErrorIcon toastIcon width="24" height="24" />
        )}
      </span>
      <ToastPrimitive.Description className={toastFont}>{description}</ToastPrimitive.Description>
      <ToastPrimitive.ToastClose
        className={toastCloseIcon}
        aria-label="Close."
        onClick={() => onOpenChange(false)}
      >
        <XIcon aria-hidden />
      </ToastPrimitive.ToastClose>
    </ToastPrimitive.Root>
  );
};
export default Toast;
