import {
  ComponentProps,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from 'react';

import { classNames } from '../../utils/classnames';
import BottomPanelModal, { BottomPanelProps } from '../Modal/BottomPanelModal';
import TooltipPrimitive from '../Tooltip';
import { baseBottomModalClass, bottomModalHeaderClass } from './index.css';

const identity = (x: any) => x;

export type OpenState = false | 'mobile' | 'desktop';

const TooltipContext = createContext<{
  content: ReactNode;
  setContent: (content: ReactNode) => void;
  open: OpenState;
  setOpen: (newOpen: OpenState) => void;
  controlledOpen: OpenState | undefined;
  setControlledOpen: ((newOpen: OpenState) => void) | undefined;
}>({
  content: null,
  setContent: () => {},
  open: false,
  setOpen: () => {},
  controlledOpen: undefined,
  setControlledOpen: undefined,
});

/**
 * Component that renders the trigger to be used in the MatchaTooltip.
 */
export function MatchaTooltipTrigger({
  children,
}: {
  /** The trigger to render */
  children: ReactNode;
}) {
  const { setOpen } = useContext(TooltipContext);
  const hasStartedTouch = useRef(false);

  return (
    <TooltipPrimitive.Trigger
      asChild
      onTouchStart={() => {
        hasStartedTouch.current = true;
      }}
      onTouchCancel={() => {
        hasStartedTouch.current = false;
      }}
      onTouchEnd={(e) => {
        const touch = e.changedTouches[0];
        const endTarget = document.elementFromPoint(touch.clientX, touch.clientY);
        // we check if we ended the touch on our component
        if (
          endTarget &&
          (e.currentTarget === endTarget || e.currentTarget.contains(endTarget as Node)) &&
          hasStartedTouch.current
        ) {
          setOpen('mobile');
        }
        hasStartedTouch.current = false;
      }}
    >
      {children}
    </TooltipPrimitive.Trigger>
  );
}

/**
 * Component that registers the content to be used by {@link MatchaTooltipPopover} and {@link MatchaTooltipBottomSheet} components.
 * This component does not render anything by itself, it is only used to populate internal context to allow the content to be re-used.
 *
 * If the content needs to be different between Popover and BottomSheet components, use the corresponding `render` props.
 */
export function MatchaTooltipContent({
  children,
}: {
  /** The content to be registered */
  children: ReactNode;
}) {
  const { setContent, content } = useContext(TooltipContext);

  useEffect(() => {
    if (content !== children) {
      setContent(children);
    }
  }, [content, children, setContent]);

  return null;
}

/**
 * This is the root component for the MatchaTooltip component. It is used to set-up and manage state and context as well as
 * the Radix-UI Tooltip Provider and Root elements.
 *
 * The **MatchaTooltip** component is a polymorphic react component that simplifies rendering of tooltips for desktop and mobile
 * by either displaying a {@link TooltipPrimitive.Content | Radix-UI Tooltip} on desktop or a {@link BottomPanelModal | Bottom Sheet} on mobile.
 *
 * The open state of both "Tooltip" types can either be handled in an uncontrolled or controlled manner, though only one of them can
 * be open at the same time.
 *
 * **Anatomy**
 *
 * ```
 * <MatchaTooltip>
 *   <MatchaTooltipTrigger>
 *   <MatchaTooltipContent>
 *   <MatchaTooltipPopover>
 *   <MatchaTooltipBottomSheet>
 * </MatchaTooltip>
 * ```
 *
 * @example
 * Tooltip for both desktop and mobile
 * ```
 * <MatchaTooltip>
 *    <MatchaTooltipTrigger>
 *      <button>Open Tooltip</button>
 *    </MatchaTooltipTrigger>
 *    <MatchaTooltipContent>
 *      <p>We are displaying a nice and informative tooltip here</p>
 *    </MatchaTooltipContent
 *    <MatchaTooltipPopover sideOffset={8} />
 *    <MatchaTooltipBottomSheet>
 * </MatchaTooltip>
 * ```
 *
 * @example
 * Tooltip only for desktop
 * ```
 * <MatchaTooltip>
 *    <MatchaTooltipTrigger>
 *      <button>Open Tooltip</button>
 *    </MatchaTooltipTrigger>
 *    <MatchaTooltipContent>
 *      <p>We are displaying a nice and informative tooltip here</p>
 *    </MatchaTooltipContent
 *    <MatchaTooltipPopover sideOffset={8} />
 * </MatchaTooltip>
 * ```
 */
export function MatchaTooltip({
  children,
  /**
   * The open state to use.
   * Setting this prop will put the component into a **controlled** mode.
   */
  open: openOverride,
  setOpen: setOpenOverride,
  // Below are Radix-UI props
  delayDuration,
  disableHoverableContent,
  skipDelayDuration,
}: {
  children: ReactNode;
  open?: OpenState;
  setOpen?: (newOpen: OpenState) => void;
} & ComponentProps<typeof TooltipPrimitive.Provider>) {
  const [content, setContent] = useState<ReactNode>(null);
  const [open, setOpen] = useState<OpenState>(false);

  return (
    <TooltipContext.Provider
      value={{
        content,
        setContent,
        open,
        setOpen,
        controlledOpen: openOverride,
        setControlledOpen: setOpenOverride,
      }}
    >
      <TooltipPrimitive.Provider
        delayDuration={delayDuration}
        disableHoverableContent={disableHoverableContent}
        skipDelayDuration={skipDelayDuration}
      >
        <TooltipPrimitive.Root
          open={openOverride !== undefined ? openOverride === 'desktop' : undefined}
        >
          {children}
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    </TooltipContext.Provider>
  );
}

type TooltipContentRenderProps = {
  /**
   * Function that allows for custom rendering of tooltip contents.
   * @param content - The registered content element
   */
  render?: (content: ReactNode) => ReactNode;
};

/**
 * This component is a wrapper for the {@link https://www.radix-ui.com/primitives/docs/components/tooltip#content | Radix-UI Tooltip Content component}
 * and accepts the same props as the component itself.
 * This component is optional. If it is not included, the popover tooltip variant will not be displayed.
 *
 * If the content needs to be wrapped or altered, the {@link TooltipContentRenderProps.render | Render prop} can be used.
 */
export const MatchaTooltipPopover = forwardRef(function TooltipDesktop({
  render = identity,
  ...props
}: TooltipContentRenderProps &
  Omit<ComponentProps<typeof TooltipPrimitive.Content>, 'children' | 'open'>) {
  const { content } = useContext(TooltipContext);

  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content sideOffset={12} {...props}>
        {render(content)}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  );
});

/**
 * This component is a wrapper for the {@link BottomPanelModal | BottomPanelModal component}
 * and accepts the same props as the component itself.
 * This component is optional. If it is not included, the bottom sheet tooltip variant will not be displayed.
 *
 * It is recommended that all Bottom Sheet tooltips contain a **Header**. The header is usually the title of the
 * trigger component that was tapped.
 *
 * If the content needs to be wrapped or altered, the {@link TooltipContentRenderProps.render | Render prop} can be used.
 *
 * @example
 * Simple mobile tooltip with header
 * ```
 * <MatchaTooltip>
 *    <MatchaTooltipTrigger>
 *      <button>Best Route</button>
 *    </MatchaTooltipTrigger>
 *    <MatchaTooltipContent>
 *      <p>Matcha compares prices across...</p>
 *    </MatchaTooltipContent
 *    <MatchaTooltipBottomSheet header="Best Route" />
 * </MatchaTooltip>
 * ```
 *
 */
export const MatchaTooltipBottomSheet = forwardRef(function TooltipMobile({
  render = identity,
  variant = 'LARGE',
  allowGestures,
  className,
  /** The header to be displayed in the bottom sheet */
  header,
}: TooltipContentRenderProps & { className?: string; header?: string } & Pick<
    BottomPanelProps,
    'variant' | 'allowGestures'
  >) {
  const { content, open, setOpen, controlledOpen, setControlledOpen } = useContext(TooltipContext);

  return (
    <BottomPanelModal
      onOpenChange={(open) => {
        if (!open) {
          if (setControlledOpen) {
            setControlledOpen(false);
          } else {
            setOpen(false);
          }
        }
      }}
      onClose={() => {
        if (setControlledOpen) {
          setControlledOpen(false);
        } else {
          setOpen(false);
        }
      }}
      isOpen={controlledOpen !== undefined ? controlledOpen === 'mobile' : open === 'mobile'}
      variant={variant}
      allowGestures={allowGestures}
    >
      <div className={classNames(baseBottomModalClass, className)}>
        {header && <h5 className={bottomModalHeaderClass}>{header}</h5>}
        {render(content)}
      </div>
    </BottomPanelModal>
  );
});
