import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { palette } from '../../../styles/palette.css';
import { ArrowUpRightIcon, ChevronDownIcon } from '../../../ui/Icons';
import { routes } from '../../../utils/routes';
import { navigationButtonWithIconClass, navigationToplevelButton } from '../index.css';
import { triggerIconClass } from '../LearnMoreButtonDropdown/index.css';
import { LearnMoreDropDownList } from '../LearnMoreDropDownList';
import { buttonIconClass, navigationClass, popoverContentClass } from './index.css';

export const FeaturesPageNavigationMenu = () => {
  return (
    <NavigationMenu.Root>
      <NavigationMenu.List className={navigationClass}>
        <NavigationMenu.Item>
          <NavigationMenu.Trigger
            className={navigationButtonWithIconClass}
            onClick={(e) => {
              // nativeEvent.detail becomes `0` if the triggering event was initiated
              // by a non-pointer device
              if (e.nativeEvent.detail !== 0) {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          >
            Features
            <ChevronDownIcon className={triggerIconClass} style={{ color: palette.gray40 }} />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className={popoverContentClass}>
            <LearnMoreDropDownList hideBlogButton hideCompanyButton isInFeaturesPage />
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link className={navigationToplevelButton} href={routes.ABOUT_MATCHA}>
            Company
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link
            target="_blank"
            className={navigationToplevelButton}
            href={routes.BLOG}
          >
            Blog
            <ArrowUpRightIcon className={buttonIconClass} aria-hidden />
          </NavigationMenu.Link>
        </NavigationMenu.Item>
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};
