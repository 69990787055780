import { solana } from '@/constants/chain';

import type { TokenResult } from '@/utils/0x/token-registry.types';

export const popularSolanaTokens = [
  {
    address: 'So11111111111111111111111111111111111111112',
    chainName: 'Solana',
    chainId: solana.id,
    type: 'SPL',
    symbol: 'SOL',
    name: 'SOL',
    decimals: 9,
    logo: 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png',
  },
  {
    address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    chainName: 'Solana',
    chainId: solana.id,
    type: 'SPL',
    symbol: 'USDC',
    name: 'USD Coin',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
  },
  {
    address: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    chainName: 'Solana',
    chainId: solana.id,
    type: 'SPL',
    symbol: 'RAY',
    name: 'Raydium',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png',
  },
  {
    address: 'JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN',
    chainName: 'Solana',
    chainId: solana.id,
    type: 'SPL',
    symbol: 'JUP',
    name: 'Jupiter',
    decimals: 6,
    logo: 'https://static.jup.ag/jup/icon.png',
  },
  {
    address: 'jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL',
    chainName: 'Solana',
    chainId: solana.id,
    type: 'SPL',
    symbol: 'JTO',
    name: 'JITO',
    decimals: 6,
    logo: 'https://metadata.jito.network/token/jto/image',
  },
  {
    address: '2zMMhcVQEXDtdE6vsFS7S7D5oUodfJHE8vd1gnBouauv',
    chainName: 'Solana',
    chainId: solana.id,
    type: 'SPL',
    symbol: 'PENGU',
    name: 'Pudgy Penguins',
    decimals: 6,
    logo: 'https://arweave.net/BW67hICaKGd2_wamSB0IQq-x7Xwtmr2oJj1WnWGJRHU',
  },
] as TokenResult[];
