import { useListBox } from '@react-aria/listbox';
import { ComboBoxState } from '@react-stately/combobox';
import { useRef, RefObject, useEffect } from 'react';

import { list } from './index.css';
import ListBoxSection from './ListBoxSection';
import Option from './Option';

interface ListBoxProps {
  className?: string;
  listBoxRef?: RefObject<HTMLUListElement>;
  state: ComboBoxState<{}>;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function ListBox({ className, state, header, footer, ...props }: ListBoxProps) {
  const ref = useRef<HTMLUListElement>(null);
  const { listBoxRef = ref } = props;
  const { listBoxProps } = useListBox(
    {
      ...props,
      disallowEmptySelection: true,
      shouldUseVirtualFocus: true,
      autoFocus: 'first',
    },
    state,
    listBoxRef,
  );

  useEffect(() => {
    if (state.isOpen && listBoxRef.current) {
      // alway scroll to top when open
      listBoxRef.current.scrollTop = 0;
    }
  }, [listBoxRef, state.isOpen]);

  return (
    <ul {...listBoxProps} ref={listBoxRef} className={className || list}>
      {header && <li>{header}</li>}

      {Array.from(state.collection).map((item) =>
        item.type === 'section' ? (
          <ListBoxSection key={item.key} section={item} state={state} />
        ) : (
          <Option key={item.key} item={item} state={state} />
        ),
      )}

      {footer && <li>{footer}</li>}
    </ul>
  );
}
