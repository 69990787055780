import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Link from 'next/link';

import { SUPPORTED_CHAIN_IDS } from '@/constants/chain';

import { ArrowUpRightIcon } from '../../../ui/Icons';
import { routes } from '../../../utils/routes';
import {
  BlogIcon,
  CompanyIcon,
  CrossChainIcon,
  DeveloperApisIcon,
  LimitOrderIcon,
  LiquiditySourcesIcon,
  MatchaAutoIcon,
  SwapTokenIcon,
} from '../utils';
import {
  arrowUpRightIconClass,
  separatorClass,
  itemDescriptionClass,
  itemLabelsClass,
  itemTitleClass,
  popoverItemClass,
  iconCircleClass,
  compactIconCircleClass,
} from './index.css';

interface LearnMoreDropdownListProps {
  hideBlogButton?: boolean;
  hideCompanyButton?: boolean;
  isInFeaturesPage?: boolean;
}

export const NavigationDropdownList = ({
  hideBlogButton,
  hideCompanyButton,
  isInFeaturesPage = false,
}: LearnMoreDropdownListProps) => {
  const featureLinkTarget = isInFeaturesPage ? '_self' : '_blank';

  return (
    <>
      <DropdownMenu.Item asChild>
        {/* It's using <a> instead of <Link target="_blank">,
            because the compiler (versel build) is complaining the following error:
              Property 'target' does not exist on type 'IntrinsicAttributes & LinkProps & { children?: ReactNode; }'.

            It seems some missing config in Matcha's end
            A new create-next-app setup with next/link does support target="_blank" rel="noopener noreferrer"
            Needs more investigation
          */}
        <a target={featureLinkTarget} href={routes.SWAP_TOKENS} className={popoverItemClass}>
          <span
            className={iconCircleClass}
            style={{
              background: 'linear-gradient(180deg, #F99704 0%, #F90 71.49%)',
            }}
          >
            <SwapTokenIcon />
          </span>
          <div className={itemLabelsClass}>
            <div className={itemTitleClass}>
              Swap Tokens
              {!isInFeaturesPage ? <ArrowUpRightIcon className={arrowUpRightIconClass} /> : null}
            </div>
            <div className={itemDescriptionClass}>Zero fee trades on 9+ million tokens</div>
          </div>
        </a>
      </DropdownMenu.Item>
      <DropdownMenu.Item asChild>
        <Link target={featureLinkTarget} href={routes.MATCHA_AUTO} className={popoverItemClass}>
          <span
            className={iconCircleClass}
            style={{
              background:
                'linear-gradient(180deg, #009658 -20.83%, rgba(0, 185, 109, 0.60) 112.5%)',
            }}
          >
            <MatchaAutoIcon />
          </span>
          <div className={itemLabelsClass}>
            <div className={itemTitleClass}>
              Gasless Swaps
              {!isInFeaturesPage ? <ArrowUpRightIcon className={arrowUpRightIconClass} /> : null}
            </div>
            <div className={itemDescriptionClass}>Go gasless with Matcha</div>
          </div>
        </Link>
      </DropdownMenu.Item>

      <DropdownMenu.Item asChild>
        <a target={featureLinkTarget} href={routes.LIQUIDITY_SOURCES} className={popoverItemClass}>
          <span
            className={iconCircleClass}
            style={{
              background: 'linear-gradient(180deg, #309AFC 0%, #02A4FF 73.31%)',
            }}
          >
            <LiquiditySourcesIcon />
          </span>
          <div className={itemLabelsClass}>
            <div className={itemTitleClass}>
              Liquidity Sources
              {!isInFeaturesPage ? <ArrowUpRightIcon className={arrowUpRightIconClass} /> : null}
            </div>
            <div className={itemDescriptionClass}>
              130+ sources across {SUPPORTED_CHAIN_IDS.length} chains{' '}
            </div>
          </div>
        </a>
      </DropdownMenu.Item>

      <DropdownMenu.Item asChild>
        <a target={featureLinkTarget} href={routes.LIMIT_ORDERS} className={popoverItemClass}>
          <span
            className={iconCircleClass}
            style={{
              background: 'linear-gradient(180deg, #FF656D 0%, #F55E66 76.04%)',
            }}
          >
            <LimitOrderIcon />
          </span>
          <div className={itemLabelsClass}>
            <div className={itemTitleClass}>
              Limit Orders
              {!isInFeaturesPage ? <ArrowUpRightIcon className={arrowUpRightIconClass} /> : null}
            </div>
            <div className={itemDescriptionClass}>Set the price you want to pay</div>
          </div>
        </a>
      </DropdownMenu.Item>

      <DropdownMenu.Item asChild>
        {/* It's using <a> instead of <Link target="_blank">,
            because the compiler (versel build) is complaining the following error:
              Property 'target' does not exist on type 'IntrinsicAttributes & LinkProps & { children?: ReactNode; }'.

            It seems some missing config in Matcha's end
            A new create-next-app setup with next/link does support target="_blank" rel="noopener noreferrer"
            Needs more investigation
          */}
        <a target={featureLinkTarget} href={routes.CROSS_CHAIN_SWAPS} className={popoverItemClass}>
          <span
            className={iconCircleClass}
            style={{
              background: 'linear-gradient(180deg, #5932F3 0%, #4E5DE7 74.61%)',
            }}
          >
            <CrossChainIcon />
          </span>
          <div className={itemLabelsClass}>
            <div className={itemTitleClass}>
              Cross Chain
              {!isInFeaturesPage ? <ArrowUpRightIcon className={arrowUpRightIconClass} /> : null}
            </div>
            <div className={itemDescriptionClass}>Swap across chains, in one trade</div>
          </div>
        </a>
      </DropdownMenu.Item>

      <hr className={separatorClass} />

      {!hideBlogButton && (
        <DropdownMenu.Item asChild>
          <a className={popoverItemClass} href={routes.BLOG} target="_blank">
            <span
              className={iconCircleClass}
              style={{
                background: 'linear-gradient(180deg, #2CB051 0%, #59B56D 77.36%)',
              }}
            >
              <BlogIcon />
            </span>
            <div className={itemLabelsClass}>
              <div className={itemTitleClass}>
                Blog
                <ArrowUpRightIcon className={arrowUpRightIconClass} />
              </div>
              <div className={itemDescriptionClass}>Stay up-to-date on the latest news</div>
            </div>
          </a>
        </DropdownMenu.Item>
      )}

      {!hideCompanyButton && (
        <DropdownMenu.Item asChild>
          <a className={popoverItemClass} href={routes.ABOUT_MATCHA} target="_blank">
            <span className={compactIconCircleClass}>
              <CompanyIcon />
            </span>
            <div className={itemLabelsClass}>
              <div className={itemTitleClass}>
                Company
                {!isInFeaturesPage ? <ArrowUpRightIcon className={arrowUpRightIconClass} /> : null}
              </div>
              <div className={itemDescriptionClass}>Get to know Matcha</div>
            </div>
          </a>
        </DropdownMenu.Item>
      )}

      {!hideCompanyButton && !hideBlogButton ? <hr className={separatorClass} /> : null}

      <DropdownMenu.Item asChild>
        <a
          className={popoverItemClass}
          href={`${routes.ZEROEX_HOME}docs/introduction/introduction-to-0x`}
          target="_blank"
        >
          <span
            className={iconCircleClass}
            style={{
              background: 'linear-gradient(180deg, #3F3F46 0%, #26272B 100%)',
            }}
          >
            <DeveloperApisIcon />
          </span>
          <div className={itemLabelsClass}>
            <div className={itemTitleClass}>
              Developer APIs
              <ArrowUpRightIcon className={arrowUpRightIconClass} />
            </div>
            <div className={itemDescriptionClass}>Embed swaps into your app with 0x</div>
          </div>
        </a>
      </DropdownMenu.Item>
    </>
  );
};
