import { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useMedia } from 'react-use';

import {
  DAI_ADDRESSES,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID,
  USDC_ADDRESSES,
  USDT_ADDRESSES,
  WBTC_ADDRESSES,
  WRAPPED_NATIVE_ADDRESSES,
  ADDITIONAL_SUGGESTED_ADDRESSES,
} from '@/constants/addresses';
import { NETWORK_NAME_PER_CHAIN_ID, solana } from '@/constants/chain';

import useTokenResults from '@/hooks/useTokenResults';

import { avatarStyles } from '@/styles/avatars.css';
import { tokens } from '@/styles/tokens.css';
import { breakpoints } from '@/styles/util';

import { TokenIcon } from '@/ui/Icons/TokenIcon';

import { TokenResult } from '@/utils/0x/token-registry.types';
import { tokenProminentColorButtonStylesByThemeHSL } from '@/utils/tokenProminentColorButtonStyles';

import {
  buttonClass,
  buttonsContainerClass,
  buttonsContainerMobileClass,
  labelClass,
  titleClass,
} from './index.css';
import { popularSolanaTokens } from './popular-solana-tokens';

const FALLBACK_TOKEN_BUTTON_STYLES = {
  light: {
    button: { background: tokens.tokenColorGray200 },
    buttonHover: { background: tokens.tokenColorGray300 },
    label: { color: tokens.tokenColorGray1600 },
  },
  dark: {
    button: { background: tokens.tokenColorGray200 },
    buttonHover: { background: tokens.tokenColorGray300 },
    label: { color: tokens.tokenColorGray1600 },
  },
};

const getTokenAddresses = (chainId: number) =>
  [
    NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[chainId],
    WRAPPED_NATIVE_ADDRESSES[chainId],
    USDT_ADDRESSES[chainId],
    USDC_ADDRESSES[chainId],
    DAI_ADDRESSES[chainId],
    WBTC_ADDRESSES[chainId],
    ADDITIONAL_SUGGESTED_ADDRESSES[chainId],
  ].filter((address) => !!address);

const LoadingSkeleton = () => (
  <ContentLoader
    width="500"
    height="36"
    uniqueKey="emptyTokenSuggestionsPills"
    foregroundColor={tokens.tokenColorGray400}
    backgroundColor={tokens.tokenColorGray200}
  >
    <rect x="0" y="0" width="70" height="36" rx="18" />
    <rect x="80" y="0" width="70" height="36" rx="18" />
    <rect x="160" y="0" width="70" height="36" rx="18" />
    <rect x="240" y="0" width="70" height="36" rx="18" />
    <rect x="320" y="0" width="70" height="36" rx="18" />
  </ContentLoader>
);

const TokenPillButton = ({
  token,
  onClick,
  chainId,
}: {
  token: TokenResult;
  onClick(token: TokenResult): void;
  chainId: number;
}) => {
  const ldButtonStyles = useMemo(
    () =>
      token?.prominentColor
        ? tokenProminentColorButtonStylesByThemeHSL(token.prominentColor)
        : FALLBACK_TOKEN_BUTTON_STYLES,
    [token],
  );

  const vars = {} as Record<string, any>;
  const txtVars = {} as Record<string, any>;

  if (ldButtonStyles.light) {
    vars['--light-bg'] = ldButtonStyles.light.button.background;
    vars['--light-bg-hover'] = ldButtonStyles.light.buttonHover.background;
    txtVars['--light-txt'] = ldButtonStyles.light.label.color;
  }
  if (ldButtonStyles.dark) {
    vars['--dark-bg'] = ldButtonStyles.dark.button.background;
    vars['--dark-bg-hover'] = ldButtonStyles.dark.buttonHover.background;
    txtVars['--dark-txt'] = ldButtonStyles.dark.label.color;
  }

  return (
    <button
      className={buttonClass}
      onClick={() => onClick(token)}
      aria-label={`${token.name} token`}
      style={vars}
    >
      <TokenIcon
        width={24}
        height={24}
        token={token}
        chainId={chainId}
        className={avatarStyles.SMALL}
      />
      <span className={labelClass} style={txtVars}>
        {token.symbol}
      </span>
    </button>
  );
};

const TokenSuggestions = ({
  chainId,
  showNetworkName,
  onClick,
}: {
  chainId: number;
  showNetworkName: boolean;
  onClick(token: TokenResult): void;
}) => {
  const isMobile = useMedia(breakpoints.mobile, false);
  const tokenAddresses = useMemo(() => getTokenAddresses(chainId), [chainId]);
  const { tokenMap } = useTokenResults(tokenAddresses, chainId);

  const tokenPillsData = useMemo(() => {
    if (tokenMap === undefined) return [];
    // Return hardcoded popular tokens for Solana because useTokenResults doesn't support Solana.
    // Also, the addresses that are passed into useTokenResults are hardcoded anyways.
    if (chainId === solana.id) return popularSolanaTokens;
    const chainTokens = tokenMap[chainId];
    return chainTokens ? Object.values(chainTokens).filter((token) => !!token) : [];
  }, [chainId, tokenMap]);

  return (
    <div>
      <span className={titleClass}>
        Most popular {showNetworkName && `on ${NETWORK_NAME_PER_CHAIN_ID[chainId]}`}
      </span>
      <div className={isMobile ? buttonsContainerMobileClass : buttonsContainerClass}>
        {!tokenPillsData.length ? (
          <LoadingSkeleton />
        ) : (
          tokenPillsData.map((token) => (
            <TokenPillButton
              key={token.address}
              token={token}
              onClick={onClick}
              chainId={chainId}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default TokenSuggestions;
