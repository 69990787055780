import * as ContextMenu from '@radix-ui/react-context-menu';
import Link from 'next/link';
import { useState, useEffect } from 'react';

import { CheckCircleIcon, CopyIcon, DownloadIcon } from '@/ui/Icons';
import ExternalLink from '@/ui/Link/ExternalLink';
import Logo from '@/ui/Logo/Logo';
import LogoCup from '@/ui/Logo/LogoCup';

import { routes } from '@/utils/routes';

// utils
import { logo, logoTextClass, logoCupClass } from '../index.css';
import { LOGO_SVG } from './constants';
import { buttonClass, contextMenuContentClass, contextMenuItemClass, iconClass } from './index.css';

const TWO_SECONDS = 2000;

const MatchaLogoButton = () => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, TWO_SECONDS);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [isCopied]);

  const handleCopyLogo = async () => {
    if (isCopied) return;

    await navigator.clipboard.writeText(LOGO_SVG);
    setIsCopied(true);
  };

  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>
        <Link aria-label="Go to homepage" href={{ pathname: routes.INDEX }}>
          <div className={logo}>
            <LogoCup className={logoCupClass} height={24} />
            <Logo className={logoTextClass} height={16} />
          </div>
        </Link>
      </ContextMenu.Trigger>

      <ContextMenu.Portal>
        <ContextMenu.Content className={contextMenuContentClass}>
          <ContextMenu.Label />
          <ContextMenu.Item
            className={contextMenuItemClass}
            onSelect={(e) => {
              e.preventDefault();
            }}
          >
            <button aria-label="Copy logo as SVG" onClick={handleCopyLogo} className={buttonClass}>
              {isCopied ? (
                <>
                  <CheckCircleIcon className={iconClass} />
                  Copied!
                </>
              ) : (
                <>
                  <CopyIcon className={iconClass} />
                  Copy Logo as SVG
                </>
              )}
            </button>
          </ContextMenu.Item>
          <ContextMenu.Item className={contextMenuItemClass}>
            <ExternalLink
              className={buttonClass}
              aria-label="Download brand assets"
              href={routes.BRAND_ASSETS}
            >
              <DownloadIcon className={iconClass} />
              Download Brand Assets
            </ExternalLink>
          </ContextMenu.Item>

          <ContextMenu.Separator />
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};

export default MatchaLogoButton;
