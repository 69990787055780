import { WRAPPED_NATIVE_ADDRESSES } from './addresses';
import { CHAIN_IDS, SupportedChainIDs } from './chain';

export interface Currency {
  address: string;
  decimals: number;
  symbol: string;
}

export const WRAPPED_NATIVE_TOKENS: { [chainId: SupportedChainIDs]: Currency } = {
  [CHAIN_IDS.MAINNET]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.MAINNET],
    decimals: 18,
    symbol: 'WETH',
  },
  [CHAIN_IDS.BSC]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.BSC],
    decimals: 18,
    symbol: 'WBNB',
  },
  [CHAIN_IDS.MATIC]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.MATIC],
    decimals: 18,
    symbol: 'WMATIC',
  },
  [CHAIN_IDS.AVALANCHE]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.AVALANCHE],
    decimals: 18,
    symbol: 'WAVAX',
  },
  [CHAIN_IDS.OPTIMISM]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.OPTIMISM],
    decimals: 18,
    symbol: 'WETH',
  },
  [CHAIN_IDS.ARBITRUM]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.ARBITRUM],
    decimals: 18,
    symbol: 'WETH',
  },
  [CHAIN_IDS.BASE]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.BASE],
    decimals: 18,
    symbol: 'WETH',
  },
  [CHAIN_IDS.SCROLL]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.SCROLL],
    decimals: 18,
    symbol: 'WETH',
  },
  [CHAIN_IDS.BLAST]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.BLAST],
    decimals: 18,
    symbol: 'WETH',
  },
  [CHAIN_IDS.LINEA]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.LINEA],
    decimals: 18,
    symbol: 'WETH',
  },
  [CHAIN_IDS.MANTLE]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.MANTLE],
    decimals: 18,
    symbol: 'WMNT',
  },
  [CHAIN_IDS.MODE]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.MODE],
    decimals: 18,
    symbol: 'WETH',
  },
  [CHAIN_IDS.UNICHAIN]: {
    address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.UNICHAIN],
    decimals: 18,
    symbol: 'WETH',
  },
};

export const MAX_UINT_256 = BigInt(
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
);
