import React from 'react';

import { tokenFormatter, usdFormatter } from '@/lib/formatting';

import IssuedByCircleBadge from '@/components/IssuedByCircleBadge';

import { ImageChainOverlayIcon } from '@/ui/Icons/TokenChainIcon';

import { formatShortAddress } from '@/utils/address';
import { ChainAddress } from '@/utils/models';
import { truncate } from '@/utils/string';

import {
  resultBalanceClass,
  resultRightLabel,
  resultClass,
  resultContainerClass,
  resultNameClass,
  resultNameContainerClass,
  resultSymbolClass,
  badgeContainerClass,
} from './index.css';

export interface SearchComboBoxItemProps {
  /** Token contract address */
  address: ChainAddress;
  /** Token balance */
  balance?: string;
  /** Token balance in USD */
  balanceUsd?: string;
  /** Chain ID for this token */
  chainId: number;
  /** Flag shows whether this token is a native version of USDC */
  isNativeUsdc?: boolean;
  /** Token logo URL */
  logoUrl: string | undefined;
  /** Token name */
  name: string;
  /** Token symbol */
  symbol: string;
}

/** Token item in token search combobox
 * @param props - Token item component props `SearchComboBoxItemProps`
 */
const SearchComboBoxItem = ({
  address,
  balance,
  balanceUsd,
  chainId,
  isNativeUsdc,
  logoUrl,
  name,
  symbol,
}: SearchComboBoxItemProps) => {
  return (
    <div className={resultContainerClass}>
      <div className={resultClass}>
        <ImageChainOverlayIcon chainId={chainId} logoUrl={logoUrl} isLargeVariant={false} />
        <div className={resultNameContainerClass}>
          <span className={resultNameClass} title={name}>
            {truncate(name, 36, true)}
          </span>
          <span className={resultSymbolClass} title={symbol}>
            {truncate(symbol, 36, true)}
          </span>
        </div>
        {isNativeUsdc && <IssuedByCircleBadge className={badgeContainerClass} />}
      </div>
      {balance ? (
        <div className={resultBalanceClass}>
          <span className={resultNameClass}>{tokenFormatter.format(balance, { symbol })}</span>
          {balanceUsd && (
            <span className={resultSymbolClass}>{usdFormatter.format(balanceUsd)}</span>
          )}
        </div>
      ) : (
        <span className={resultRightLabel}>{formatShortAddress(address)}</span>
      )}
    </div>
  );
};

export default React.memo(SearchComboBoxItem);
