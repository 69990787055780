import { solana } from '@/constants/chain';

import { TokenResult } from '../../../utils/0x/token-registry.types';
import ImageWithFallback from './fallback';

type SafeNumber = number | `${number}`;

interface IconProps {
  className?: string;
  token?: Pick<TokenResult, 'name' | 'address' | 'logo'>;
  logoUrl?: string | null;
  // This will indicate if it is a TokenIcon with small chain on left.
  chainId?: number | undefined;
  containerStyle?: { [key: string]: string | number };
  height?: SafeNumber;
  width?: SafeNumber;
  props?: React.SVGProps<SVGSVGElement>;
}

export const TokenIcon = ({ className, token, logoUrl, height, width, chainId }: IconProps) => (
  <ImageWithFallback
    alt={token ? `${token.name} Token Logo` : 'Token Logo'}
    className={className}
    height={height}
    width={width}
    // If the Image Upload fails, this component will show the Purple(?) Icon
    src={token?.logo ?? logoUrl ?? ''}
    // TODO: MAT-4376 remove this once we have a solana tokens served through our API and their logos are served through our CDN
    useRegularImg={!chainId || chainId === solana.id}
  />
);
