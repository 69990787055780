import { forwardRef, HTMLProps, Ref } from 'react';

import { badgeVariantsClass, BadgeVariants, contentWrapperClass } from './index.css';

type BadgeProps = {
  variant: BadgeVariants;
} & Omit<HTMLProps<HTMLDivElement>, 'className'>;

export const Badge = forwardRef(function Badge(
  { variant, content, children, role = 'status', ...divProps }: BadgeProps,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <div ref={ref} className={badgeVariantsClass[variant]} role={role} {...divProps}>
      <div className={contentWrapperClass[variant]}>{children}</div>
    </div>
  );
});
