import Decimal from 'decimal.js-light';

import * as errors from './errors';
import Formatter from './formatter';
import type { Amount, Boundaries, TokenFormatterOptions } from './types';

/**
 * Displays human readable amounts of a given token value.
 *
 * @remarks
 * An `options` object can be used to specify the erc20 `decimals` value when
 * passing in the raw amount in wei units. If `decimals` is not passed in, the
 * function will assume the amount passed in has already been divided by its
 * `decimals` value.
 *
 * If a `symbol` is passed in to the options object, it will be appended to the
 * end of the formatted amount.
 *
 * @example
 * ```ts
 * const formatter = new TokenFormatter();
 * const options = { decimals: 6, symbol: 'USDC'};
 * formatter.format(215000000000000, options);
 * // outputs: "215000000 USDC"
 * ```
 */
class TokenFormatter extends Formatter {
  protected boundaries: Boundaries;

  constructor() {
    super();

    // set boundaries
    this.boundaries = {
      xs: new Decimal(1e-6),
      sm: new Decimal(1e-2),
      md: new Decimal(1e2),
      lg: new Decimal(1e7),
    };
  }

  /**
   * Formats a token amount value to be human readable.
   *
   * @param amount - The amount to format in wei
   * @param options - An options object to specify the symbol or decimals value
   */
  public format(
    amount: Amount,
    { decimals, fullAmount = false, mode = 'read', precision, symbol }: TokenFormatterOptions = {},
  ): string {
    return mode === 'write'
      ? this.formatWriteable(amount.toString())
      : this.formatReadable(amount, { decimals, fullAmount, precision, symbol });
  }

  /**
   * Formats a token amount value in read mode.
   *
   * @param amount - The amount to format in wei
   * @param options - An options object to specify the symbol or decimals value
   */
  private formatReadable(amount: Amount, options?: TokenFormatterOptions): string {
    // null checks
    if (!this.boundaries.xs || !this.boundaries.sm || !this.boundaries.md || !this.boundaries.lg) {
      throw errors.missingBoundaries;
    }

    // variables
    let humanReadable = '';
    let value = new Decimal(amount);

    // divide by decimals if needed
    if (options?.decimals) {
      const decimals = new Decimal(options.decimals);
      value = value.div(new Decimal(10).toPower(decimals));
    }

    // configure Decimal to prevent scientific notation
    if (options?.fullAmount) {
      Decimal.config({
        toExpNeg: -9999,
        toExpPos: 9999,
      });
    }

    // formatting logic
    if (value.eq(0)) {
      // zero
      humanReadable = '0';
    } else if (value.lt(this.boundaries.xs)) {
      // below 0.000001
      humanReadable = options?.fullAmount ? value.toString() : this.toExponential(value);
    } else if (value.gte(this.boundaries.xs) && value.lt(this.boundaries.sm)) {
      // between 0.000001 and 0.01
      humanReadable = this.toSigFigs(
        value,
        typeof options?.precision === 'number' ? options.precision : 3,
      );
    } else if (value.gte(this.boundaries.sm) && value.lt(this.boundaries.md)) {
      // between 0.01 and 100
      humanReadable = this.toSigFigs(
        value,
        typeof options?.precision === 'number' ? options.precision : 6,
      );
    } else if (value.gte(this.boundaries.md) && value.lt(this.boundaries.lg)) {
      // between 100 and 10000000
      humanReadable = this.toFixedLocaleString(value, 2);
    } else if (value.gte(this.boundaries.lg)) {
      // above 10000000
      humanReadable = options?.fullAmount
        ? this.toFixedLocaleString(value, 2)
        : this.toExponential(value);
    } else {
      // should never happen
      throw errors.amountOutOfRange;
    }

    // append symbol if needed
    if (options?.symbol) humanReadable += ' ' + options.symbol;

    return humanReadable;
  }

  /**
   * Formats a token amount value in write mode.
   *
   * @param amount - The amount to format in wei
   */
  private formatWriteable(amount: string): string {
    let formatted = '';

    // remove all non-numeric characters
    const sanitized = amount.replace(/[^\d.]/g, '');

    if (sanitized) {
      // count fractional characters to the right of the decimal (defaults to zero)
      const [, fraction] = sanitized.split('.');
      // maximum value is 20 (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#minimumfractiondigits)
      let fractionDigits = fraction?.length ? Math.min(fraction.length, 20) : 0;

      // add commas and preserve decimal places
      formatted = new Decimal(sanitized).toNumber().toLocaleString(this.locale, {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      });
    }

    // format with commas
    return formatted;
  }
}

export default TokenFormatter;
