import solanaTokenList from '@/../public/data/solana-tokens.json';
import { DigitalAssetWithToken, DigitalAsset } from '@metaplex-foundation/mpl-token-metadata';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import Decimal from 'decimal.js-light';
import { useMemo } from 'react';
import useSwr from 'swr';
import { formatUnits } from 'viem';

import { solana } from '@/constants/chain';

import type { Price } from '@/utils/defined.types';
import { ChainAddress, isSolanaAddress } from '@/utils/models';
import { routes } from '@/utils/routes';
import { getSolanaConnection } from '@/utils/solana';
import { fetcher } from '@/utils/swr';
import { FetchTokenBalancesResponse } from '@/utils/tokenBalances';

import { WRAPPED_NATIVE_ADDRESSES } from '../constants/addresses';
import { TokenResult, mapSolanaTokensToTokenResults } from '../utils/0x/token-registry.types';
import { isTokenAddressNativeAsset } from '../utils/multichain';
import useTokenResults from './useTokenResults';
import { useUSDPrices } from './useUSDPrices';

export type DigitalAssetWithTokenAndImage = Omit<DigitalAssetWithToken, 'metadata'> & {
  metadata: DigitalAsset['metadata'] & {
    image: string;
  };
};

const solanaTokens = mapSolanaTokensToTokenResults(solanaTokenList);

const solTokensMap = solanaTokens.reduce<Record<string, TokenResult>>((map, currentItem) => {
  const { address } = currentItem;
  map[address] = currentItem;
  return map;
}, {});

export interface TokenResultWithBalance extends TokenResult {
  balance: string;
  balanceUsd: string;
}

/**
 * Get all token balances for an address and return them in a list with the token info included
 * @param address - user wallet address
 * @param chainId - chain Id of the network to check balances
 * @returns TokenResultWithBalance[] | undefined
 */
export default function useTokensWithBalances(
  address: ChainAddress | undefined,
  chainId: number | undefined,
): TokenResultWithBalance[] | undefined {
  const { data: splTokenBalances, error } = useSwr<DigitalAssetWithTokenAndImage[]>(
    address,
    async () => {
      if (address && isSolanaAddress(address)) {
        const response = await fetch(`/api/solana-token-balances?ownerAddress=${address}`);
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message);
        }
        return data;
      }
    },
  );

  const fungibleAssetsMap = useMemo(() => {
    if (splTokenBalances && !error) {
      return splTokenBalances.reduce<Record<string, DigitalAssetWithTokenAndImage>>((map, item) => {
        map[item.publicKey] = item;
        return map;
      }, {});
    }
    return {};
  }, [splTokenBalances, error]);

  // fetch all balances for the designated address
  const key = useMemo(() => {
    if (chainId === solana.id) {
      return '';
    }

    return address && chainId
      ? `${routes.api.TOKENS_BALANCE}?address=${address}&chainId=${chainId}`
      : '';
  }, [address, chainId]);
  const { data: balancesData } = useSwr<FetchTokenBalancesResponse>(key, fetcher);

  // get contract addresses for all tokens with balances
  const tokenAddresses = useMemo(
    () => balancesData?.balances.map(({ contractAddress }) => contractAddress.toLowerCase()),
    [balancesData],
  );

  // get the token info for tokens with balances
  const { tokenMap } = useTokenResults(tokenAddresses, chainId);

  const usdPriceParams = useMemo(
    () =>
      chainId
        ? tokenAddresses?.map((address) => ({
            address: isTokenAddressNativeAsset(address, chainId)
              ? WRAPPED_NATIVE_ADDRESSES[chainId]
              : address,
            networkId: chainId,
          }))
        : undefined,
    [tokenAddresses, chainId],
  );

  const usePricesArgForSvm = useMemo(
    () =>
      splTokenBalances
        ? splTokenBalances.map((b) => ({
            address: b.publicKey,
            networkId: solana.id,
          }))
        : [],
    [splTokenBalances],
  );

  const { data: solanaTokenPrices } = useUSDPrices(usePricesArgForSvm, false);

  const { data: usdPrices } = useUSDPrices(usdPriceParams, false);

  const { data: nativeBalance } = useSwr([address], async () => {
    if (address) {
      const connection = await getSolanaConnection();
      const ownerPublicKey = new PublicKey(address);
      const balance = await connection.getBalance(ownerPublicKey, 'confirmed');

      return {
        decimals: 9,
        amount: balance.toString(),
        uiAmount: balance / LAMPORTS_PER_SOL,
        uiAmountString: `${balance / LAMPORTS_PER_SOL}`,
      };
    }
  });

  // combine token info and balances
  const tokensWithBalances = useMemo(() => {
    if (chainId === solana.id) {
      if (!solanaTokenPrices) return undefined;

      return solanaTokenPrices
        .filter((price): price is Price => price !== null)
        .map((price) => {
          const tokenResult = solTokensMap[price.address];

          const asset = fungibleAssetsMap[price.address];
          const balance = asset.token.amount;
          const formattedBalance = formatUnits(BigInt(balance), asset.mint.decimals);
          const balanceUsd = new Decimal(price.priceUsd).mul(formattedBalance).toFixed(2);
          const name = asset.metadata.name;
          const symbol = asset.metadata.symbol;
          const logo = asset.metadata.image;

          if (asset.publicKey === WRAPPED_NATIVE_ADDRESSES[solana.id] && nativeBalance) {
            const formattedBalance = nativeBalance.uiAmountString;
            const balanceUsd = new Decimal(price.priceUsd).mul(formattedBalance).toFixed(2);

            return {
              ...tokenResult,
              balanceUsd,
              type: 'SPL',
              chainId: solana.id,
              chainName: solana.name,
              balance: formattedBalance,
              decimals: nativeBalance.decimals,
              name: solana.nativeCurrency.name,
              symbol: solana.nativeCurrency.symbol,
              address: asset.publicKey as ChainAddress,
              logo: tokenResult ? tokenResult.logo : logo,
            };
          }

          return {
            ...tokenResult,
            name,
            symbol,
            balanceUsd,
            type: 'SPL',
            chainId: solana.id,
            chainName: solana.name,
            balance: formattedBalance,
            decimals: asset.mint.decimals,
            address: asset.publicKey as ChainAddress,
            logo: tokenResult ? tokenResult.logo : logo,
          };
        })
        .sort((a, b) => (new Decimal(a.balanceUsd).greaterThan(b.balanceUsd) ? -1 : 1));
    } else {
      return chainId && balancesData && usdPrices
        ? balancesData.balances
            .reduce((arr, { contractAddress, balance }, index): TokenResultWithBalance[] => {
              const token = tokenMap?.[chainId]?.[contractAddress];
              const price = usdPrices[index];

              // Only return tokens that fit this criteria
              // - token info exists
              // - non-zero balance
              // - usd price exists
              // - not a flagged token
              // - not a blocked token
              // - balance is at least $0.01 (checked later below)
              const canAddToken =
                token &&
                BigInt(balance) > 0n &&
                price &&
                !(token.tokenListsFlag.length > 0) &&
                !(token.tokenListsBlock.length > 0);

              if (canAddToken) {
                const formattedBalance = formatUnits(BigInt(balance), token.decimals);
                const balanceUsd = new Decimal(price.priceUsd).mul(formattedBalance);

                // Only add tokens with a balance of at least $0.01
                const MIN_TOKEN_BALANCE_USD = 0.01;
                if (balanceUsd.greaterThanOrEqualTo(MIN_TOKEN_BALANCE_USD)) {
                  arr.push({
                    ...token,
                    balance: formattedBalance,
                    balanceUsd: balanceUsd.toFixed(2),
                  });
                }
              }
              return arr;
            }, [] as TokenResultWithBalance[])
            .sort((a, b) => (new Decimal(a.balanceUsd).greaterThan(b.balanceUsd) ? -1 : 1))
        : undefined;
    }
  }, [
    chainId,
    tokenMap,
    usdPrices,
    balancesData,
    nativeBalance,
    solanaTokenPrices,
    fungibleAssetsMap,
  ]);

  return tokensWithBalances;
}
