import { TokenResultWithBalance } from '../../../hooks/useTokensWithBalances';
import { TokenResult } from '../../../utils/0x/token-registry.types';
import { SearchComboBoxItemProps } from './SearchComboBox/SearchComboBoxItem';

export const getSearchComboBoxItemPropsForTokens = (
  tokens: TokenResult[] | TokenResultWithBalance[] | undefined,
): SearchComboBoxItemProps[] | undefined =>
  tokens?.map((token) => ({
    address: token.address,
    chainId: token.chainId,
    name: token.name,
    symbol: token.symbol,
    logoUrl: token.logo ?? undefined,
    balance: 'balance' in token ? token.balance : undefined,
    balanceUsd: 'balanceUsd' in token ? token.balanceUsd : undefined,
    isNativeUsdc: token.isNativeUsdc,
  }));
