import { useOption } from '@react-aria/listbox';
import { ListState } from '@react-stately/list';
import { Node } from '@react-types/shared';
import { useRef } from 'react';

import { baseOption, selectedOption } from './index.css';

interface Props {
  item: Node<unknown>;
  state: ListState<unknown>;
}

export default function Option({ item, state }: Props) {
  const ref = useRef<HTMLLIElement>(null);
  const isSelected = state.selectionManager.isSelected(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  const { optionProps } = useOption(
    {
      key: item.key,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
      shouldUseVirtualFocus: true,
    },
    state,
    ref,
  );

  return (
    <li {...optionProps} ref={ref} className={isFocused ? selectedOption : baseOption}>
      {item.rendered}
    </li>
  );
}
