import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { ChevronDownIcon } from '../../../ui/Icons';
import { headerNavigationDropdownButtonClass } from '../index.css';
import { LearnMoreDropDownList } from '../LearnMoreDropDownList';
import { popoverClass, triggerIconClass, navigationClass } from './index.css';

interface LearnMoreButtonDropdownProps {
  hideBlogButton?: boolean;
}

export const LearnMoreButtonDropdown = ({ hideBlogButton }: LearnMoreButtonDropdownProps) => (
  <NavigationMenu.Root>
    <NavigationMenu.List className={navigationClass}>
      <NavigationMenu.Item>
        <NavigationMenu.Trigger
          onClick={(e) => {
            // nativeEvent.detail becomes `0` if the triggering event was initiated
            // by a non-pointer device
            if (e.nativeEvent.detail !== 0) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          className={headerNavigationDropdownButtonClass}
        >
          Learn more
          <ChevronDownIcon className={triggerIconClass} />
        </NavigationMenu.Trigger>
        <NavigationMenu.Content className={popoverClass}>
          <LearnMoreDropDownList hideBlogButton={hideBlogButton} />
        </NavigationMenu.Content>
      </NavigationMenu.Item>
    </NavigationMenu.List>
  </NavigationMenu.Root>
);
