import * as Dialog from '@radix-ui/react-dialog';
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';

import { ModalProps } from '..';
import {
  dialogContentContainerClass,
  dialogOverlayClass,
  dialogStyles,
  dialogVerticalCenteredContainerClass,
} from '../../../styles/dialog.css';
import { classNames } from '../../../utils/classnames';
import {
  contentScrollContainer,
  dialogBackdropClass,
  dragHandleClass,
  sheetClass,
} from './index.css';

const Y = 100;

const AnimatedOverlay = animated(Dialog.Overlay);
const AnimatedContent = animated(Dialog.Content);

export interface BottomPanelProps extends Omit<ModalProps, 'onOpenChange'> {
  onOpenChange: (open: boolean) => void;
  variant?: 'DEFAULT' | 'LARGE';
  allowGestures?: boolean;
}

const config = {
  tension: 400,
  friction: 49,
  mass: 2,
};

type ScrollState = 'scrolling' | 'top' | 'bottom';

const BottomPanelModal = ({
  children,
  isOpen: desiredState,
  onClose,
  onOpenChange,
  variant = 'DEFAULT',
  allowGestures = true,
}: BottomPanelProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(desiredState);
  const scrollState = useRef<ScrollState>('top');
  const touchStartScrollState = useRef<ScrollState>('top');
  const handleRef = useRef<HTMLDivElement>(null);

  const [{ y }, springRef] = useSpring(() => ({
    y: Y,
    config,
  }));

  const open = useCallback(
    ({ canceled }: { canceled: boolean }) => {
      // when cancel is true, it means that the user passed the upwards threshold
      // so we change the spring config to create a nice wobbly effect
      if (!canceled) springRef.set({ y: 100 });
      springRef.start({
        y: 0,
        immediate: false,
        config,
      });
      setIsOpen(true);
    },
    [springRef],
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
    onClose();
    springRef.set({ y: 0 });
  }, [onClose, springRef]);

  const close = useCallback(
    (velocity = 0) => {
      springRef.start({
        y: 100,
        immediate: false,
        config: { ...config, velocity },
      });
      setTimeout(() => handleClose(), 300);
    },
    [handleClose, springRef],
  );

  useEffect(() => {
    if (isOpen === desiredState) return;
    if (desiredState) {
      open({ canceled: false });
    } else {
      close();
    }
  }, [close, desiredState, isOpen, open]);

  const bindGesture = useDrag(
    ({ event, first, last, velocity: [, vy], direction: [, dy], movement: [, my], cancel }) => {
      const sheetHeight = contentRef?.current?.getBoundingClientRect?.()?.height ?? 1;

      // If the user is on the last touch, we close the modal if the following rules are fulfilled:
      // 1. The gesture started with a scrollState of "top"
      // 2. The user did a fast flick downwards or the user moved the modal down more than 50%
      if (last) {
        if (
          touchStartScrollState.current === 'top' &&
          ((vy > 0.5 && dy > 0 && my !== 0) || my > sheetHeight * 0.5)
        ) {
          close(vy);
          return;
        }
        open({ canceled: true });
        return;
      }
      if (vy !== 0 || my !== 0 || dy !== 0) event.stopPropagation();

      if (!last) {
        springRef.start({ y: (my / sheetHeight) * 100, immediate: true });
      }
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    },
  );

  const gestureHandlers = isOpen && allowGestures ? bindGesture() : {};

  function handleKeyEscape(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Escape') onClose();
  }
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <AnimatedOverlay
          className={classNames(dialogOverlayClass, dialogBackdropClass)}
          style={{
            pointerEvents: 'none',
          }}
        />
        <div className={dialogVerticalCenteredContainerClass}>
          <div className={dialogContentContainerClass}>
            <animated.div
              className={sheetClass}
              style={{
                transform: y.to((y) => `translateY(${y}%)`),
              }}
            />
            <AnimatedContent
              className={dialogStyles[`${variant}_BOTTOM_PANEL`]}
              onKeyDown={handleKeyEscape}
              onOpenAutoFocus={(e) => {
                e.preventDefault();
              }}
              style={{
                transform: y.to((y) => `translateY(${y}%)`),
              }}
              ref={contentRef}
            >
              <div className={dragHandleClass} ref={handleRef} {...gestureHandlers} />
              <div
                className={contentScrollContainer}
                {...gestureHandlers}
                onScrollCapture={(e) => {
                  const { offsetHeight, scrollHeight, scrollTop } = e.currentTarget;
                  if (scrollTop === 0) {
                    scrollState.current = 'top';
                  } else if (offsetHeight + scrollTop === scrollHeight) {
                    scrollState.current = 'bottom';
                  } else {
                    scrollState.current = 'scrolling';
                  }
                }}
              >
                {children}
              </div>
            </AnimatedContent>
          </div>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default BottomPanelModal;
