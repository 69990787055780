import Decimal from 'decimal.js-light';

import type { Amount, Boundaries } from './types';

/**
 * Base Formatter class to display human readable amounts.
 *
 * @remarks
 * Any class that derives from this must implement the `format()` method, and
 * set the `boundaries` property.
 */
abstract class Formatter {
  protected abstract boundaries: Boundaries;
  protected locale: string;

  constructor() {
    // static en-US locale to consistently display fiat amounts
    this.locale = 'en-US';
  }

  /**
   * Formats an amount value to be human readable.
   *
   * @param amount - The amount to format.
   */
  public abstract format(amount: Amount): string;

  /**
   * Returns an exponential representation of the given amount.
   *
   * @param value - The amount to format.
   */
  protected toExponential(value: Decimal): string {
    return value.toExponential(2);
  }

  /**
   * Returns a fixed locale string representation of the given amount.
   *
   * @param value - The amount to format.
   * @param precision - The number of digits to show after the decimal point.
   * @param options - Additional options to pass to `toLocaleString()`.
   */
  protected toFixedLocaleString(
    value: Decimal,
    precision: number,
    options?: Intl.NumberFormatOptions,
  ): string {
    return Number(value.toFixed(precision)).toLocaleString(this.locale, options);
  }

  /**
   * Returns a significant figures representation of the given amount.
   *
   * @param value - The amount to format.
   * @param precision - The number of significant figures to display.
   */
  protected toSigFigs(value: Decimal, precision: number): string {
    return value.toSignificantDigits(precision).toString();
  }
}

export default Formatter;
