import FiatFormatter from './fiatFormatter';
import GasFormatter from './gasFormatter';
import {
  percentageFormatter as iPercentageFormatter,
  percentageFormatterNoSign as ipercentageFormatterNoSign,
} from './percentageFormatter';
import ShortFormatter from './shortFormatter';
import TokenFormatter from './tokenFormatter';

// formatters
const gasFormatter = new GasFormatter();
const usdFormatter = new FiatFormatter('USD');
const tokenFormatter = new TokenFormatter();
const shortFormatter = new ShortFormatter();
const percentageFormatter = iPercentageFormatter;
const percentageFormatterNoSign = ipercentageFormatterNoSign;

// exports
export {
  gasFormatter,
  usdFormatter,
  tokenFormatter,
  shortFormatter,
  percentageFormatter,
  percentageFormatterNoSign,
};
