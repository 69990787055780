interface IconProps {
  className?: string;
  height?: string | number;
  width?: string | number;
  props?: React.SVGProps<SVGSVGElement>;
}
const FallbackIcon: React.FC<IconProps> = ({ className, height, width, ...props }: IconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.317 16.4123C21.941 16.0535 22.6747 15.9223 23.3881 16.0421C24.1015 16.1618 24.7486 16.5247 25.2148 17.0665C25.6809 17.6083 25.936 18.294 25.935 19.0022L25.935 19.0045C25.935 19.7079 25.3754 20.4413 24.3186 21.1306C23.8369 21.4449 23.3442 21.687 22.9659 21.8514C22.7789 21.9327 22.6253 21.9928 22.5219 22.0313C22.4761 22.0484 22.43 22.0649 22.3837 22.0808C21.5818 22.3433 21.1486 23.1919 21.4161 23.977C21.6837 24.7626 22.5516 25.1872 23.3546 24.9253L23.6131 24.8337C23.7611 24.7785 23.9667 24.698 24.2107 24.5919C24.6945 24.3815 25.3512 24.0613 26.0188 23.6258C27.2605 22.8159 28.9993 21.3005 29 19.0058C29.0019 17.5897 28.4917 16.2186 27.5596 15.1352C26.6273 14.0516 25.3331 13.3258 23.9063 13.0863C22.4794 12.8469 21.012 13.1092 19.764 13.8269C18.5159 14.5446 17.5677 15.6713 17.0873 17.0075C16.8064 17.7886 17.226 18.6447 18.0244 18.9195C18.8228 19.1943 19.6977 18.7838 19.9786 18.0026C20.2188 17.3345 20.6929 16.7712 21.317 16.4123ZM22.9925 28.0011C22.1462 28.0011 21.46 28.6724 21.46 29.5006C21.46 30.3287 22.1462 31 22.9925 31H23.0079C23.8543 31 24.5404 30.3287 24.5404 29.5006C24.5404 28.6724 23.8543 28.0011 23.0079 28.0011H22.9925Z"
        fill="#FCFCFD"
      />
      <defs>
        <filter
          id="filter0_d_1666_1898"
          x="0"
          y="0"
          width="46"
          height="46"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1666_1898" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1666_1898"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FallbackIcon;
