import { useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { TokenResult } from '../utils/0x/token-registry.types';
import { routes } from '../utils/routes';
import { objectToQueryString } from '../utils/string';
import { fetcher } from '../utils/swr';

const DEFAULT_RESULT_LIMIT = 10;

interface UseTrendingTokensParams {
  chainIds?: number[];
  limit?: number;
  onSuccess?: () => void;
  resolution?: '60' | '240' | '720' | '1D';
}

export const useTrendingTokens = ({
  chainIds,
  limit,
  onSuccess,
  resolution = '1D',
}: UseTrendingTokensParams) => {
  const canSearch = !!chainIds && chainIds.length;
  const parameters = useMemo(
    () => ({
      chainIds,
      limit: limit || DEFAULT_RESULT_LIMIT,
      resolution,
    }),
    [chainIds, limit, resolution],
  );

  const url = useMemo(
    () =>
      canSearch ? `${routes.api.TRENDING_TOKENS}${objectToQueryString(parameters)}` : undefined,
    [canSearch, parameters],
  );

  const config: SWRConfiguration = {
    revalidateIfStale: false, // note: we may want to reenable this in the future, if token data is dynamic.
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  if (onSuccess) config.onSuccess = onSuccess;

  return useSWR<TokenResult[]>(url, fetcher, config);
};
