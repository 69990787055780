/**
 * This function is a utility that can conditionally combine classnames.
 *
 * @example
 * Variadic combination of class names that might be falsy
 * ```
 * const className1 = "wrapper"
 * const className2 = undefined;
 *
 * classNames(className1, className2) // "wrapper"
 * ```
 *
 * @example
 * Controlling class names via object
 * ```
 * classNames({wrapper: true, "mt-1": true, hidden: false}) // "wrapper mt-1"
 * ```
 *
 * @example
 * Mixing strings and objects
 * ```
 * classNames("wrapper", {"mt-1": true, hidden: false}) // "wrapper mt-1"
 * ```
 * */
export function classNames(...names: (string | Record<string, any> | undefined)[]): string {
  let out = '';
  for (const cname of names) {
    if (!cname) continue;
    if (typeof cname === 'object') {
      for (const [key, value] of Object.entries(cname)) {
        if (value) {
          out += ' ' + key;
        }
      }
    } else {
      // out is a string
      out += ' ' + cname;
    }
  }

  return out;
}
