import { useMemo } from 'react';

import { useUSDPrices } from '../../../../hooks/useUSDPrices';
import { usdFormatter } from '../../../../lib/formatting';
import { LoadingSpinner } from '../../../../ui/Icons/Animated/LoadingSpinner';
import type { GetPriceInput as PriceParams } from '../../../../utils/defined.types';
// utils
import { isNullAmount } from '../../../../utils/numbers';

interface BalanceUsdProps {
  /** The account balance formatted as a string. */
  balance: string;
  /** Account address. */
  address: string;
  /** ID of the current network. */
  chainId: number;
  /** Additional class name. */
  className?: string;
}

/**
 * Displays the balance information of an account.
 */
const BalanceUsd = ({
  address,
  balance,
  chainId,
  className = '',
}: BalanceUsdProps): JSX.Element | null => {
  // vars
  let balanceUSD: string = '';

  // fetch price for a unit of token/usd
  const priceParams: PriceParams[] | undefined = useMemo(
    () => [
      {
        address,
        networkId: chainId,
      },
    ],
    [address, chainId],
  );
  const { data, isLoading } = useUSDPrices(priceParams);

  if (data) {
    const [price] = data;

    if (price && !isNullAmount(price.priceUsd)) {
      // calculate USD balance from the latest price
      balanceUSD = usdFormatter.format(price.priceUsd * parseFloat(balance));
    }
  }

  // render
  if (isLoading) return <LoadingSpinner />;

  if (!balanceUSD) return null;

  return <span className={className}>{balanceUSD}</span>;
};

export default BalanceUsd;
