export const Teal = () => (
  <>
    <g clipPath="url(#clip0_10485_31986)">
      <g filter="url(#filter0_dd_10485_31986)">
        <circle cx="20" cy="20" r="20" fill="#F8F8F8" />
      </g>
      <g opacity="0.8" filter="url(#filter1_f_10485_31986)">
        <ellipse
          cx="19.7664"
          cy="19.5209"
          rx="19.7664"
          ry="19.5209"
          transform="matrix(-0.288205 -0.957569 0.936518 -0.35062 6.92383 33.8701)"
          fill="#50FFCA"
        />
      </g>
      <g opacity="0.8" filter="url(#filter2_f_10485_31986)">
        <ellipse
          cx="16.7726"
          cy="16.2469"
          rx="16.7726"
          ry="16.2469"
          transform="matrix(-0.482888 -0.875682 0.798496 -0.602 4.72949 37.0171)"
          fill="#14C585"
        />
      </g>
      <g opacity="0.8" filter="url(#filter3_f_10485_31986)">
        <path
          d="M39.4116 -8.34306C48.3017 -1.07231 41.5311 35.537 38.0932 33.1312C35.9959 31.6636 33.5165 19.2487 26.9327 13.9285C22.724 10.5274 15.189 13.0091 10.3898 13.3601C-1.91679 14.2601 30.4473 -15.6745 39.4116 -8.34306Z"
          fill="#69CF80"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_10485_31986"
        x="-12"
        y="-10"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.6875 0 0 0 0 0.744318 0 0 0 0 1 0 0 0 0.06 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10485_31986" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.549757 0 0 0 0 0.610463 0 0 0 0 0.929167 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_10485_31986"
          result="effect2_dropShadow_10485_31986"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_10485_31986"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_f_10485_31986"
        x="-15.6455"
        y="-28.0347"
        width="70.3076"
        height="72.2651"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_10485_31986" />
      </filter>
      <filter
        id="filter2_f_10485_31986"
        x="-21.6924"
        y="-21.0991"
        width="62.5918"
        height="67.2959"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_10485_31986" />
      </filter>
      <filter
        id="filter3_f_10485_31986"
        x="-8.36719"
        y="-25.5005"
        width="68.1777"
        height="74.7451"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_10485_31986" />
      </filter>
      <clipPath id="clip0_10485_31986">
        <rect width="40" height="40" rx="20" fill="white" />
      </clipPath>
    </defs>
  </>
);
