import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { unichain } from 'viem/chains';

import { flexCenter } from '@/components/History/Table/styles.css';

import { NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID } from '@/constants/addresses';
import { solana } from '@/constants/chain';

import { SOLANA_PHASE1_COOKIE_KEY, useFeature } from '@/hooks/useFeature';

import { ArrowRightLarge, XIcon } from '@/ui/Icons';

import { routes } from '@/utils/routes';

import {
  arrowClass,
  closeIconClass,
  containerClass,
  solanaContainerClass,
  solanaContentContainerClass,
  contentContainerClass,
  newPillIconClass,
  solanaCloseIconClass,
  arrowClassSolana,
  solanaIconContainer,
} from './index.css';

const NewPill = () => <span className={newPillIconClass}>New</span>;

const AnnouncementBanner = () => {
  const isSolanaPhase1 = useFeature(SOLANA_PHASE1_COOKIE_KEY);
  const newChain = isSolanaPhase1 ? solana : unichain;
  const { isAnnouncementBannerVisible, hideBanner } = useIsAnnouncementBannerVisible();
  const router = useRouter();
  const onNewChain = [newChain.id, newChain.name.toLowerCase()].some((param) =>
    router.asPath.startsWith(`/tokens/${param}`),
  );

  // TODO: link to the unichain/solana blog post when it's ready, and remove this early exit.
  if (onNewChain) return null;

  if (isSolanaPhase1) {
    return (
      <div
        hidden={!isAnnouncementBannerVisible}
        className={isSolanaPhase1 ? solanaContainerClass : containerClass}
      >
        <Link
          href={{
            pathname: routes.TOKEN_PROFILE,
            query: {
              chainId: newChain.id.toString(),
              contractAddresses: [NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[newChain.id]],
            },
          }}
        >
          <div className={solanaContentContainerClass}>
            <span className={flexCenter}>
              Matcha is live on{' '}
              <Image
                width={24}
                height={24}
                alt={newChain.name}
                className={solanaIconContainer}
                src={`/images/chains/${newChain.name.toLowerCase()}.svg`}
              />{' '}
              {newChain.name}! Learn more
              <ArrowRightLarge className={arrowClassSolana} />
            </span>
          </div>
        </Link>
        <button className={solanaCloseIconClass} onClick={hideBanner}>
          <XIcon />
        </button>
      </div>
    );
  }

  return (
    <div className={containerClass} hidden={!isAnnouncementBannerVisible}>
      <Link
        href={{
          pathname: routes.TOKEN_PROFILE,
          query: {
            chainId: unichain.id.toString(),
            contractAddresses: [NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[unichain.id]],
          },
        }}
      >
        <div className={contentContainerClass}>
          <NewPill />
          <div>
            Unichain is live on Matcha! Learn more
            <ArrowRightLarge className={arrowClass} />
          </div>
        </div>
      </Link>
      <button className={closeIconClass} onClick={hideBanner}>
        <XIcon />
      </button>
    </div>
  );
};

export default AnnouncementBanner;

const LOCAL_STORAGE_KEY = 'hide-apiv2-announcement-banner';

type UseIsAnnouncementBannerVisibleReturnType = {
  // Is visible?
  isAnnouncementBannerVisible: boolean;
  // Hide callback
  hideBanner: () => void;
};

export const useIsAnnouncementBannerVisible = (): UseIsAnnouncementBannerVisibleReturnType => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHidden, setIsHidden] = useLocalStorage(LOCAL_STORAGE_KEY, false);

  // Note: we have to set the state the UI relies on, because otherwise the server-rendered
  // version of the component will not match the client-rendered version.
  // We need to be aligned on not showing the banner initially.
  useEffect(() => {
    setIsVisible(!isHidden);
  }, [isHidden]);

  return {
    isAnnouncementBannerVisible: isVisible,
    hideBanner: () => setIsHidden(true),
  };
};
