export const Green = () => (
  <>
    <g clipPath="url(#clip0_9340_116206)">
      <g filter="url(#filter0_dd_9340_116206)">
        <circle cx="20" cy="20" r="20" fill="#F8F8F8" />
      </g>
      <g opacity="0.8" filter="url(#filter1_f_9340_116206)">
        <ellipse
          cx="19.7664"
          cy="19.5209"
          rx="19.7664"
          ry="19.5209"
          transform="matrix(-0.288205 -0.957569 0.936518 -0.35062 6.92188 33.87)"
          fill="#67CAE9"
        />
      </g>
      <g opacity="0.8" filter="url(#filter2_f_9340_116206)">
        <ellipse
          cx="16.7726"
          cy="16.2469"
          rx="16.7726"
          ry="16.2469"
          transform="matrix(-0.482888 -0.875682 0.798496 -0.602 4.73047 37.0169)"
          fill="#FCFF5C"
        />
      </g>
      <g opacity="0.8" filter="url(#filter3_f_9340_116206)">
        <path
          d="M39.4135 -8.34306C48.3037 -1.07231 41.5331 35.537 38.0951 33.1312C35.9979 31.6636 33.5184 19.2487 26.9347 13.9285C22.726 10.5274 15.1909 13.0091 10.3917 13.3601C-1.91484 14.2601 30.4493 -15.6745 39.4135 -8.34306Z"
          fill="#8CD317"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_9340_116206"
        x="-12"
        y="-10"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.6875 0 0 0 0 0.744318 0 0 0 0 1 0 0 0 0.06 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9340_116206" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.549757 0 0 0 0 0.610463 0 0 0 0 0.929167 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_9340_116206"
          result="effect2_dropShadow_9340_116206"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_9340_116206"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_f_9340_116206"
        x="-15.6484"
        y="-28.0347"
        width="70.3086"
        height="72.2652"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_9340_116206" />
      </filter>
      <filter
        id="filter2_f_9340_116206"
        x="-21.6914"
        y="-21.0991"
        width="62.5898"
        height="67.2958"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_9340_116206" />
      </filter>
      <filter
        id="filter3_f_9340_116206"
        x="-8.36328"
        y="-25.5004"
        width="68.1758"
        height="74.7449"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_9340_116206" />
      </filter>
      <clipPath id="clip0_9340_116206">
        <rect width="40" height="40" rx="20" fill="white" />
      </clipPath>
    </defs>
  </>
);
