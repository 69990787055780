import type { ConnectedWallet, ConnectedSolanaWallet } from '@privy-io/react-auth';
import { useEffect, useState } from 'react';

import { getDisconnectedKey } from '@/utils/solana';

export function useSolWalletDisconnected(wallet: ConnectedWallet | ConnectedSolanaWallet) {
  const [disconnectedWalletName, setDisconnectedWalletName] = useState(() => {
    try {
      const item = localStorage.getItem(getDisconnectedKey(wallet));
      return item ? wallet.meta.name : undefined;
    } catch (error) {
      console.error('Error getting localStorage', error);
    }
  });

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === getDisconnectedKey(wallet)) {
        setDisconnectedWalletName(wallet.meta.name.toLowerCase());
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [wallet]);

  return {
    isDisconnected: disconnectedWalletName?.toLowerCase() === wallet.meta.name.toLowerCase(),
    name: disconnectedWalletName,
  };
}
