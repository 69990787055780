import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { TokenResult } from '../../utils/0x/token-registry.types';

const MAX_RECENTLY_VIEWED_COUNT = 20;

interface SearchStore {
  recentlyViewed: TokenResult[];
  addRecentlyViewedToken: (token: TokenResult) => void;
  clearRecentlyViewedTokens: () => void;
}

export const useSearchStore = createWithEqualityFn<SearchStore>()(
  persist(
    (set, get) => ({
      recentlyViewed: [],
      addRecentlyViewedToken: (newRecentlyViewedToken: TokenResult) => {
        // add new recently viewed token to the start of the list
        const recentlyViewed = [
          { ...newRecentlyViewedToken },
          ...get().recentlyViewed.filter(
            (token) =>
              !(
                token.address === newRecentlyViewedToken.address &&
                token.chainId === newRecentlyViewedToken.chainId
              ),
          ), // dedupe
        ].slice(0, MAX_RECENTLY_VIEWED_COUNT); // keep first n recently viewed

        set({
          recentlyViewed,
        });
      },
      clearRecentlyViewedTokens: () => {
        set({
          recentlyViewed: [],
        });
      },
    }),
    {
      name: 'matcha-search-storage',
    },
  ),
  shallow,
);

export const recentlyViewedTokensSelectors = (state: SearchStore) => ({
  recentlyViewed: state.recentlyViewed,
  addRecentlyViewedToken: state.addRecentlyViewedToken,
  clearRecentlyViewedTokens: state.clearRecentlyViewedTokens,
});
