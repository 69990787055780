import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useTransition, animated, useSpring } from '@react-spring/web';
import React, { useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';

import { useMeasure } from '@/hooks/useMeasure';

import { btnStyles } from '../../../styles/buttons.css';
import { popoverIconClass, popoverItemClass } from '../../../styles/popover.css';
import { breakpoints } from '../../../styles/util';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DarkModeIcon,
  DocumentIcon,
  DotsIcon,
  HelpIcon,
  RequestFeatureIcon,
} from '../../../ui/Icons';
import { routes } from '../../../utils/routes';
import { DarkmodeTogglePage } from '../DarkmodeToggle';
import { separatorClass } from '../LearnMoreDropDownList/index.css';
import { NavigationDropdownList } from '../NavigationDropdownList';
import {
  popoverClass,
  chevronIconClass,
  navBackHeaderClass,
  navBackIconButtonClass,
  navBackPopoverItemClass,
  navBackTitleClass,
  popoverItemLabelClass,
  chevronRightIconClass,
} from './index.css';

enum STEPS {
  MAIN = 1,
  LEARN_MORE = 2,
  THEME = 3,
}

const AdditionalOptionsDropdown: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(STEPS.MAIN);
  const [clickedOn, setClickedOn] = useState<STEPS | null>(null);
  const [isClosingLearnMore, setIsClosingLearnMore] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // For “slide” direction
  const [slideDirection, setSlideDirection] = useState<'forward' | 'backward'>('forward');

  const learnMoreButtonRef = useRef<HTMLDivElement>(null);
  const appearanceButtonRef = useRef<HTMLDivElement>(null);
  const closingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isMobile = useMedia(breakpoints.mobile, false);

  const transitions = useTransition(currentStep, {
    key: currentStep,
    from: {
      opacity: 0,
      transform: slideDirection === 'forward' ? 'translateX(100px)' : 'translateX(-100px)',
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0px)',
    },
    leave: {
      opacity: 0,
      transform: slideDirection === 'forward' ? 'translateX(-100px)' : 'translateX(100px)',
      position: 'absolute',
    },
    config: { tension: 225, friction: 30, duration: 200 },
  });

  const { bounds, nodeRef: contentRef } = useMeasure<HTMLDivElement>();

  const [springs, api] = useSpring(() => ({
    height: 0,
    config: { tension: 450, friction: 25, duration: 200, mass: 0.6, clamp: true },
  }));

  useEffect(() => {
    api.start({ height: bounds.height });
  }, [bounds.height, api]);

  function handleOpenChange(open: boolean) {
    setIsDropdownOpen(open);
    if (!open) {
      setCurrentStep(STEPS.MAIN);
      setClickedOn(null);
      setIsClosingLearnMore(false);
    }
  }

  function makeSelectHandler(step: STEPS) {
    return (e: Pick<Event, 'preventDefault'>) => {
      e.preventDefault();
      setSlideDirection('forward');
      setClickedOn(step);
      setCurrentStep(step);
    };
  }

  function handleGoBack(e: React.MouseEvent | React.KeyboardEvent) {
    e.preventDefault();
    setIsClosingLearnMore(true);
    setSlideDirection('backward');

    closingTimeoutRef.current = setTimeout(() => {
      setCurrentStep(STEPS.MAIN);
      setIsClosingLearnMore(false);
    }, 100);
  }

  // Cleanup any timeouts on unmount
  useEffect(() => {
    return () => {
      if (closingTimeoutRef.current) {
        clearTimeout(closingTimeoutRef.current);
      }
    };
  }, []);

  // When going back to MAIN, restore focus to the “Learn More” button
  useEffect(() => {
    if (currentStep === STEPS.MAIN && clickedOn) {
      if (clickedOn === STEPS.LEARN_MORE && learnMoreButtonRef.current) {
        learnMoreButtonRef.current.focus();
      } else if (clickedOn === STEPS.THEME && appearanceButtonRef.current) {
        appearanceButtonRef.current.focus();
      }
      setClickedOn(null);
    }
  }, [currentStep, clickedOn]);

  return (
    <DropdownMenu.Root onOpenChange={handleOpenChange} open={isDropdownOpen}>
      <DropdownMenu.Trigger className={btnStyles.SECONDARY_MD_ICON_ONLY}>
        <DotsIcon />
        <VisuallyHidden>Additional Options</VisuallyHidden>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content align="end" forceMount className={popoverClass}>
          <animated.div
            style={{
              ...springs,
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <div ref={contentRef}>
              {transitions((style, step) => (
                <animated.div style={style}>
                  {step === STEPS.MAIN ? (
                    <MainMenuContent
                      isMobile={isMobile}
                      onSelectLearnMore={makeSelectHandler(STEPS.LEARN_MORE)}
                      onSelectAppearance={makeSelectHandler(STEPS.THEME)}
                      learnMoreButtonRef={learnMoreButtonRef}
                      appearanceButtonRef={appearanceButtonRef}
                    />
                  ) : step === STEPS.THEME ? (
                    <DarkmodeTogglePage onGoBack={handleGoBack} />
                  ) : (
                    <LearnMoreMenuContent
                      onGoBack={handleGoBack}
                      isClosingLearnMore={isClosingLearnMore}
                    />
                  )}
                </animated.div>
              ))}
            </div>
          </animated.div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default AdditionalOptionsDropdown;

interface MainMenuContentProps {
  isMobile: boolean;
  onSelectLearnMore: (e: Pick<Event, 'preventDefault'>) => void;
  onSelectAppearance: (e: Pick<Event, 'preventDefault'>) => void;
  learnMoreButtonRef: React.RefObject<HTMLDivElement>;
  appearanceButtonRef: React.RefObject<HTMLDivElement>;
}
function MainMenuContent({
  isMobile,
  onSelectAppearance,
  onSelectLearnMore,
  learnMoreButtonRef,
  appearanceButtonRef,
}: MainMenuContentProps) {
  return (
    <div>
      <DropdownMenu.Item
        className={popoverItemClass}
        ref={appearanceButtonRef}
        onClick={onSelectAppearance}
      >
        <DarkModeIcon className={popoverIconClass} />
        Appearance
        <ChevronRightIcon className={chevronRightIconClass} />
      </DropdownMenu.Item>

      {isMobile && (
        <DropdownMenu.Item className={popoverItemClass} onClick={() => window.open(routes.BLOG)}>
          <DocumentIcon className={popoverIconClass} />
          Blog
        </DropdownMenu.Item>
      )}

      <DropdownMenu.Item className={popoverItemClass} onClick={() => window.open(routes.HELP)}>
        <HelpIcon className={popoverIconClass} />
        Help Center
      </DropdownMenu.Item>

      <DropdownMenu.Item
        className={popoverItemClass}
        onClick={() => window.open(routes.REQUEST_FEATURE)}
      >
        <RequestFeatureIcon className={popoverIconClass} />
        Request Feature
      </DropdownMenu.Item>

      {isMobile && (
        <>
          <hr className={separatorClass} />
          <DropdownMenu.Item
            className={popoverItemClass}
            onSelect={onSelectLearnMore}
            ref={learnMoreButtonRef}
          >
            <span className={popoverItemLabelClass}>Learn more</span>
            <ChevronRightIcon className={chevronRightIconClass} />
          </DropdownMenu.Item>
        </>
      )}
    </div>
  );
}

interface LearnMoreMenuContentProps {
  onGoBack: (e: React.MouseEvent | React.KeyboardEvent) => void;
  isClosingLearnMore: boolean;
}
function LearnMoreMenuContent({ onGoBack, isClosingLearnMore }: LearnMoreMenuContentProps) {
  return (
    <div className={isClosingLearnMore ? 'closing' : ''}>
      <DropdownMenu.Item
        className={navBackPopoverItemClass}
        onClick={onGoBack}
        aria-label="Back to main menu"
      >
        <div className={navBackHeaderClass}>
          <div className={navBackIconButtonClass}>
            <ChevronLeftIcon className={chevronIconClass} />
          </div>
          <span className={navBackTitleClass}>Learn more</span>
        </div>
      </DropdownMenu.Item>

      <NavigationDropdownList />
    </div>
  );
}
