import Decimal from 'decimal.js-light';

import * as errors from './errors';
import Formatter from './formatter';
import type { Amount, Boundaries } from './types';

/**
 * Displays human readable amounts of a given gas value in wei.
 *
 * @example
 * ```ts
 * const formatter = new GasFormatter();
 * formatter.format(1000000000000); // wei
 * // outputs: "1000 Gwei"
 * ```
 */
class GasFormatter extends Formatter {
  protected boundaries: Boundaries;

  constructor() {
    super();

    // set boundaries
    this.boundaries = {
      xs: new Decimal(1e-6),
      sm: new Decimal(1),
      md: new Decimal(1e3),
      lg: new Decimal(1e7),
    };
  }

  /**
   * Formats a gas amount value to be human readable.
   *
   * @param amount - The amount to format in wei
   */
  public format(amount: Amount): string {
    // null checks
    if (!this.boundaries.xs || !this.boundaries.sm || !this.boundaries.md || !this.boundaries.lg) {
      throw errors.missingBoundaries;
    }

    // variables
    let humanReadable = '';
    const value = new Decimal(amount).div(1e9); // always display gas in Gwei

    // formatting logic
    if (value.eq(0)) {
      // zero
      humanReadable = '0';
    } else if (value.lt(this.boundaries.xs) || value.gte(this.boundaries.lg)) {
      // less than 0.000001 Gwei or greater than 10000000 Gwei
      humanReadable = this.toExponential(value);
    } else if (value.gte(this.boundaries.xs) && value.lt(this.boundaries.sm)) {
      // between 0.000001 and 1 Gwei
      humanReadable = this.toSigFigs(value, 2);
    } else if (value.gte(this.boundaries.sm) && value.lt(this.boundaries.md)) {
      // between 1 and 1000 Gwei
      humanReadable = this.toFixedLocaleString(value, 1);
    } else if (value.gte(this.boundaries.md) && value.lt(this.boundaries.lg)) {
      // between 1000 and 10000000 Gwei
      humanReadable = this.toFixedLocaleString(value, 0);
    } else {
      // should never happen
      throw errors.amountOutOfRange;
    }

    return `${humanReadable} Gwei`;
  }
}

export default GasFormatter;
